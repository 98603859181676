.mobileLogIn-main {
  position: absolute;
  color: #ffffff;
  right: 0;
  top: 0;
  left: 0px;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: rgba(16, 18, 25, 0.95);
}

.mobileLogIn-block {
  display: block;
}

.mobileLogIn-none {
  display: none;
}

.mobileLogIn-container {
  position: relative;
}

.mobileLogIn-close {
  position: absolute;
  right: 0;
  padding: 10px 45px;
}

.mobileLogIn-logo {
  height: 100px;
  width: 110px;
  margin: 55px 0;
}

.mobileLogIn-welcome {
  font-size: 26px;
  font-weight: bold;
  text-shadow: 0 0 19px 0 rgba(255, 255, 255, 0.5);
}

.mobileLogIn-buttons-container {
  margin-top: 45px;
  margin-bottom: 20px;
  width: 75%;
}

.mobileLogIn-logInBtn {
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  border: none;
  padding: 10px 0;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}

.mobileLogIn-registerBtn {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  width: 100%;
  background: none;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  border-image-source: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
}

.mobileLogIn-footer {
  font-size: 9px;
  width: 70%;
  letter-spacing: 0.05px;
  line-height: 12px;
}

.termsOfService {
  color: #16a0ff;
}
