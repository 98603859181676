.payment-main {
  background-color: var(--right-section-sub-main);
  height: 60%;
  width: 100%;
  padding: 20px;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-title {
  font-size: 18px;
}

.payment-field {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-input {
  width: 100%;
  border: 0px;
  border-radius: 4px;
  background: var(--payment-input-bg);
  padding: 12px;
  font-size: 14px;
  outline: none;
  border: var(--payment-input-border)
}

.payment-input::placeholder {
  color: #67696f;
}

.confirm-btn {
  background: linear-gradient(270deg, #7270ff, #00aaff);
  padding: 10px;
  font-size: 18px;
  border-radius: 4px;
  text-align: center;
}

.confirm-btn:hover {
  opacity: 0.95;
}

@media screen and (max-height: 796px) {
  .payment-main {
    height: 75%;
  }
}

@media screen and (max-width: 768px) {
  .payment-main {
    height: 300px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.error-message {
  color: red;
}

@media screen and (max-width: 666px) {
  .payment-title,
  .confirm-btn {
    font-size: 14px;
    margin-right: 0px;
  }
  .payment-input,
  .payment-amount,
  .payment-promoCode {
    font-size: 12px;
  }
  .payment-main {
    background: var(--deposit-main-bg);
  }
  .payment-input {
    padding: 8px;
  }
  .confirm-btn {
    padding: 8px;
  }
}
