.games-card-container {
  background: var(--home-background);
  box-sizing: border-box;
  padding: 20px;
}
.provider-img-alt-text img {
  line-height: 50px;
  color: white;
}
.casino-section-body {
  opacity: 0;
  background: var(--games-card-footer);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.casino-section-body:hover {
  opacity: 1;
}

.games-category > img,
.games-category-hover:hover > img {
  color: var(--text-color);
  filter: var(--side-menu-img);
}
#games-category-h2,
#games-category-hover-h2:hover {
  color: var(--text-color) !important;
}
.games-category h2 {
  color: var(--white);
}

.provider-image {
  box-sizing: border-box;
  cursor: pointer;
  margin: 0px 10px;
  height: 81px !important;
  width: 119px !important;
}

.provider-image:hover, .seleceted-provider {
  -ms-transform: scale(1.05);
  /* IE 9 */
  -webkit-transform: scale(1.05);
  /* Safari 3-8 */
  transform: scale(1.05);
  box-sizing: border-box;
  border: 2px solid rgba(0, 145, 255, 0.5);
  border-radius: 4px;
  background: #192437;
}

.game-provider-loader {
  width: 150px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
}
.navbar-carousel {
  background: #191e29;
}
.navbar-carousel .react-multi-carousel-list {
  position: revert;
}

.navbar-carousel .react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
  background: #191e29;
}
.navbar-carousel .react-multiple-carousel__arrow--right::before {
  color: rgba(0, 145, 255, 0.5);
  font-weight: 900;
}

.navbar-carousel .react-multiple-carousel__arrow--left::before {
  color: rgba(0, 145, 255, 0.5);
  font-weight: 900;
}
.navbar-carousel .react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
  background: #191e29;
}

.navbar-carousel .react-multiple-carousel__arrow--left:hover::before {
  font-size: 23px;
}

.navbar-carousel .react-multiple-carousel__arrow--right:hover::before {
  font-size: 23px;
}

.navbar-carousel .react-multiple-carousel__arrow:hover {
  background: #191e29;
}

.gamesRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.gamesBannerImage-container {
  background: var(--dark-background);
  min-width: 350px;
}

.gamesBannerImage {
  width: 100%;
}

.allGames_none {
  display: none;
}

.allGames-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}

.allGames-scroll {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  margin: 30px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftStyle {
  height: 100%;
  width: 3px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  box-shadow: 0 0 10px 0 #537fff;
}

.allGames-icon {
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .gamesRow {
    padding: 0 50px;
  }
  .gamesCol {
    height: auto;
    width: 195px;
  }
}

@media screen and (max-width: 480px) {
  .gamesRow {
    padding: 0 20px;
    justify-content: center;
  }
  .gamesCol {
    width: 175px;
  }
  .casino-section-body {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .gamesBannerImage {
    background-size: cover;
  }
  .gamesRow {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    justify-self: center;
    align-items: center;
    align-content: center;
  }

  .gamesCol {
    height: auto;
    width: 175px;
  }

  .allGames_none {
    display: block;
  }

  .provider-image {
    height: 60px !important;
    width: 88.1px !important;
    margin: 0;
  }

  .provider-img {
    height: 60px !important;
    width: 88.1px !important;
  }
}
