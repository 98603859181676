.subscribe {
  background-color: rgb(25, 30, 41);
}

.subscribe-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.subscribe-body-inner {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-content-main {
  width: 50%;
  margin-left: 50px;
}

.img-casino_2 {
  height: 75px;
  width: 75px;
}

.subscribe-body-inner h1 {
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 26px;
  font-weight: bold;
  margin: 20px 0 10px;
}

.subscribe-body-inner p {
  color: #b5b5b5;
  font-family: var(--Roboto);
  font-size: 15px;
}

.subscribe-body-inner input {
  position: relative;
  box-sizing: border-box;
  height: 54px;
  width: 80%;
  border: 1px solid #3d4855;
  border-radius: 6px;
  background-color: #293443;
  outline: none;
  color: white;
  padding-left: 10px;
}

.subscribe-body-inner button {
  position: absolute;
  right: 120px;
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  width: 139px;
  border: none;
}

.custom-btn {
  top: 0;
  bottom: 0;
  right: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.subscribe-body-inner input:placeholder-shown {
  padding: 0 1rem;
  color: #848589;
  font-family: var(--Roboto);
  font-size: 15px;
}

.subscribeBackground {
  height: 400px;
  background-size: auto;
  position: relative;
}

@media screen and (max-width: 768px){  
  .subscribe{
   position: relative;
   height: 285px;
   margin-bottom: 30px;
   margin: 20px 20px 0 20px; 
  }

  .subscribeBackground{
    position: absolute;
    top: 0;
    left: 0px;
    height: 282px;
    width: 100%;
  }

  .subscribe-content-main{
    margin-left: -5px;
    width: 65%;
  }

  .img-casino_2{
    height: 49px;
    width: 49px;
  }

  .subscribe-body-inner h1{
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
  }

  .subscribe-body-inner p{
    font-size: 13px;
    line-height: normal;
  }

  .subscribe-body-inner input{
    height: 40px;
    width: 90%;
    position: relative;
  }

  .subscribe-body-inner button{
    height: 35px;
    position: absolute;
    right: 25px;
  }

  .subscribe-2{
    height: 260px;
    width: 332px;
    margin-right: -20px;
  }
}

@media screen and (max-width: 425px) {
  .subscribe{
    margin: auto;
  }

  .subscribeBackground {
    height: 150px;
    position: static;
    width: auto;
    border-radius: 5px;
  }

  .subscribe-content-main {
    width: 95%;
    margin: 0 auto;
    height: 100%;
    margin-top: -30px;
  }

  .img-casino_2 {
    height: 28px;
    width: 28px;
  }

  .subscribe-body-inner input {
    height: 34px;
    width: 90%;
    font-size: 12px;
  }

  .subscribe-body-inner input:placeholder-shown {
    font-size: 13px;
  }

  .subscribe-body-inner button {
    height: 28px;
    width: 90px;
    font-size: 12px;
  }

  .custom-btn {
    right: 45px;
  }

  .subscribe-main-col {
    display: flex;
    flex-direction: row-reverse;
  }

  .subscribe-body-inner h1 {
    font-size: 14px;
    line-height: normal;
    margin-top: 7px;
  }

  .subscribe-body-inner p {
    font-size: 10px;
    line-height: normal;
  }

  .subscribe-2 {
    display: none;
  }
}
