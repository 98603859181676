.blackNav-main{
    background-color: var(--right-section-sub-main);
}

.black_nav{
    padding: 0 33px;
    padding-right: 180px;
}

.nav-El{
  padding: 12px 0;
  color: var(--text-color);
  font-family: Poppins;
  font-size: 14px;
}

@media screen and (max-width: 666px) {
  .blackNav-hidden{
    display: none;
  }

  .nav-El{
    padding-right: 60px;
    font-size: 12px;
  }
}
