.light {
  --sidebar-bg: #ffffff;
  --menu-light--clr: #0091ff;
  --menu-light-bg-clr: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  --menu-light-shadow: 0 2px 29px 0 rgba(0, 145, 255, 0.5);
  --navbar-bg: #ffffff;
  --navbar-text-clr: #ebebeb;
  --navbar-right-btn: #ebebeb;
  --navbar-right-text-clr: #141822;
  --gray-background: #ffffff;
  --input-backgroud: #ebebeb;
  --text-color: #141822;
  --login-background: linear-gradient(#ebebeb, #ebebeb) padding-box,
    linear-gradient(270deg, #7270ff 0%, #00aaff 100%) border-box;
  --home-background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(147, 212, 255, 0.3) 100%
  );
  --card-heading-clr: rgba(20, 24, 34, 0.7);
  --card-heading-text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  --footer-background: #ffffff;
  --games-header-background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 145, 255, 0.1) 100%
  );
  --games-name-color: rgba(20, 24, 34, 0.7);
  --game-background: #e8ebee;
  --house-fun-bg: hsla(0, 0%, 100%, 0.85);
  --side-menu-selected-name: #0091ff;
  --side-menu-selected: rgba(0, 0, 0, 0.05);
  --side-menu-img: invert(43%) sepia(62%) saturate(4520%) hue-rotate(188deg)
    brightness(104%) contrast(105%);
  --footer-text-clr: #a3a3a3;
  --mail-img: brightness(0) invert(0);
  --top-bets-bg: #f6f6f6;
  --casino-menu-icons: rgba(0, 145, 255, 0.3);
  --hr-line: linear-gradient(
    45deg,
    #0091ff 30%,
    #0091ff 40%,
    #ffffff 60%,
    transparent
  );
  --games-card-footer: rgba(255, 255, 255, 0.9);
  --safe-better-text: rgba(20, 24, 34, 0.7);
  --ant-dropdown-bg: #f6f6f6;
  --deposit-text-clr-active: #a3a3a3;
  --deposit-main-bg: #ffffff;
  --deposit-nav-text-clr: rgba(20, 24, 34, 0.7);
  --deposit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  --right-section-bg: #ffffff;
  --right-section-sub-main: #ebebeb;
  --payment-input-bg: #f8f8f8;
  --payment-input-border: 1px solid #dfdfdf;
  --card-bg-selected: rgba(255, 255, 255, 0);
  --live-casin-content-bg: rgba(255, 255, 255, 0.9);
  --navbar-mobile-light-bg: #ffffff;
  --navbar-mobile-light-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  --blue-line: radial-gradient(
    25px 100px ellipse at 50% 80%,
    #00aaff,
    #00aaff,
    #d4eeff4d
  );
  --modal-background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(198, 206, 218, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  --info-modal-background: rgba(255, 255, 255, 0.8);
  --navbar-mobile-bg-clr: #ffffff;
  --navbar-sidebar-bg: linear-gradient(
    270deg,
    rgba(0, 145, 255, 0) 0%,
    rgba(5, 118, 205, 0.159) 100%
  );
  --side-menu-img-drop-shadow: "";
  --table-border-bottom: 1px solid #dfdfdf;
  --login-input-active-autofill: 0 0 0px 500px #ffffff inset;
  --support-header: #ebebeb;
  --search-text-clr: #A3A3A3;
  --search-text-border: #DFDFDF;
  --casino-bg-clr: rgba(223,242,255,0.25);
  --sidebar-main-container-bg: #6c6969b3;
}

.dark {
  --sidebar-main-container-bg: #050505bf;
  --casino-bg-clr: #191e29;
  --search-text-border: #303238;
  --search-text-clr: #67696F;
  --table-border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  --navbar-sidebar-bg: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  --navbar-mobile-bg-clr: #272c3b;
  --navbar-mobile-light-bg: #181b25;
  --navbar-mobile-light-shadow: "";
  --live-casin-content-bg: rgba(16, 18, 25, 0.9);
  --card-bg-selected: rgba(255, 255, 255, 0.07);
  --payment-input-border: "";
  --right-section-sub-main: #101219;
  --payment-input-bg: #272a33;
  --deposit-box-shadow: "";
  --right-section-bg: #141822;
  --deposit-text-clr-active: rgba(255, 255, 255, 0.5);
  --ant-dropdown-bg: #101219;
  --safe-better-text: #ffffff;
  --sidebar-bg: #101219;
  --menu-light--clr: "";
  --menu-light-bg-clr: "";
  --menu-light-shadow: "";
  --navbar-bg: #181b25;
  --navbar-text-clr: #141822;
  --navbar-right-btn: #272a33;
  --navbar-right-text-clr: #ffffff;
  --gray-background: #181b25;
  --input-backgroud: #272a33;
  --card-heading-clr: #ffffff;
  --card-heading-text-shadow: 0 5px 5px 0 #000000;
  --text-color: #ffffff;
  --login-background: linear-gradient(#181b25, #181b25) padding-box,
    linear-gradient(270deg, #7270ff 0%, #00aaff 100%) border-box;
  --home-background: #191e29;
  --footer-background: #101219;
  --games-header-background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  --games-name-color: #ffffff;
  --game-background: #141822;
  --house-fun-bg: rgba(0, 0, 0, 0.76);
  --side-menu-selected-name: #ffffff;
  --side-menu-selected: rgba(255, 255, 255, 0.08);
  --side-menu-img: brightness(0) invert(1);
  --side-menu-img-drop-shadow: drop-shadow(-1px 1px 9px white);
  --footer-text-clr: #67696f;
  --mail-img: brightness(0) invert(1);
  --top-bets-bg: #141822;
  --casino-menu-icons: #92959a;
  --hr-line: linear-gradient(
    45deg,
    #0091ff 30%,
    #0091ff 40%,
    #141822 60%,
    transparent
  );
  --games-card-footer: rgba(16, 18, 25, 0.9);
  --deposit-main-bg: #141822;
  --deposit-nav-text-clr: #909298;
  --blue-line: radial-gradient(
    25px 100px ellipse at 50% 80%,
    #00aaff,
    #00aaff,
    #191e29
  );
  --modal-background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  --info-modal-background: rgba(255, 255, 255, 0.8);
  --login-input-active-autofill: 0 0 0px 500px #202229 inset;
  --support-header: #101219;
}
