.asked-questions,
.contact-details {
  font-size: 24px;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  background-color: var(--support-header);
  padding: 30px;
  height: 94px;
  width: 100%;
  color: var(--text-color);
}

.title-border-right-top {
  height: 1px;
  width: 60%;
  background: var(--hr-line);
  margin-bottom: 20px;
}

.title-border-right {
  height: 1px;
  width: 60%;
  background: var(--hr-line);
  margin: 20px 0;
}

.title-border-right-short {
  height: 1px;
  width: 35%;
  background: var(--hr-line);
  margin-bottom: 30px;
}

.quest-title {
  font-weight: bold;
}
.quest-title h4 {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
  margin-bottom: 0;
}

.quest-title-img {
  padding: 0 10px 0 30px;
  cursor: pointer;
}
.quest-img {
  filter: var(--mail-img);
}
.faq-title {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.06px;
  line-height: 37px;
  margin-bottom: 0;
}

.quest-answer {
  color: #898b90;
  font-size: 14px;
  padding: 10px 60px 0;
}

.none {
  display: none;
}

@media screen and (max-width: 1020px) {
  .faq-title {
    font-size: 20px;
  }
  .asked-questions,
  .contact-details {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .asked-questions {
    display: none;
  }
  .quest-title-img {
    padding: 0 5px 0 10px;
  }
  .quest-title h4 {
    font-size: 15px;
    letter-spacing: 0.03px;
    line-height: 13px;
  }
  .quest-answer {
    font-size: 12px;
    letter-spacing: 0.02px;
    line-height: 10px;
    padding: 5px 10px 0 60px;
  }
}
