.main-container {
  padding: 45px;
  background: var(--home-background);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.text-white {
  color: var(--text-color);
  text-align: center;
}

.text-gray {
  color: var(--deposit-text-clr-active);
}

.pointer {
  cursor: pointer;
  margin-right: 20px;
}

.flex {
  display: flex;
  align-items: center;
}

.main {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-border {
  height: 1px;
  width: 90%;
  background: var(--hr-line);
}

@media screen and (max-width: 768px) {
  .main {
    flex-direction: column;
    height: fit-content;
  }
}

@media screen and (max-width: 666px) {
  .main {
    /* background-color: #141822;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
    border-radius: 10px; */
  }
  .main-container {
    padding: 20px;
  }
  .pointer {
    cursor: pointer;
    margin-right: 0px;
  }
}

@media screen and (max-width: 480px) {
  .pointer {
    width: 100%;
  }
}
