@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap");

:root {
  --clr-white: #fff;
  --dark-background: #191e29;
  --primary-blue: #00aaff;
  --secondary-blue: #16a0ff;
  --fontFamilyPopins: Poppins;
  --fontSiz-14: 14px;
  --letterSpacing-0: 0;
  --lineHeight-16: 16px;
  --text-center: center;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.p-10 {
  padding: 10px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: -10px !important;
  text-align: center !important;
}


.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  margin-top: 15px !important;
}
.white-content-bg,
.white-content-bg input {
  background-color: #ffffff !important;
  box-shadow: 0 0 0px 500px #ffffff inset !important;
  -webkit-text-fill-color: #141822 !important;
  padding: 0.5rem !important;
}
.white-content-bg input {
  color: #141822 !important;
  font-size: 13px !important;
  letter-spacing: 0.03px !important;
  line-height: 14px !important;
}
.white-content-bg:focus {
  background-color: var(--sidebar-bg);
  color: #141822 !important;
  box-shadow: 0 0 0px 500px #ffffff inset !important;
  -webkit-text-fill-color: #141822 !important;
}
.white-content {
  background-color: var(--sidebar-bg);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2) !important;
}
.light-btn-style {
  background-color: #ebebeb;
  color: #141822;
}
.sidebar-menu-bg {
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  box-shadow: 0 2px 29px 0 rgba(0, 145, 255, 0.5);
  color: #0091ff;
}
.sidebar-menu-color {
  color: #0091ff;
}
.menu-light-bg {
  color: var(--menu-light--clr);
  background: var(--menu-light-bg-clr);
  box-shadow: var(--menu-light-shadow);
}
.white-bg {
  background-color: #ffffff !important;
}
.black-color {
  color: #141822 !important;
}
.home-light-bg {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(147, 212, 255, 0.3) 100%
  ) !important;
}
.home-light-color {
  color: rgba(20, 24, 34, 0.7);
}
.home-light-hc-bg {
  background-color: rgba(255, 255, 255, 0.85) !important;
}
.home-light-hc-color {
  color: #141822 !important;
}
.home-light-card-color {
  color: rgba(20, 24, 34, 0.7) !important;
}
.home-light-heading-bg {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 145, 255, 0.1) 100%
  ) !important;
}
.home-light-heading-color {
  color: #141822 !important;
}
.live-casino-bg {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(147, 212, 255, 0.3) 100%
  ) !important;
}
.live-casino-content-bg {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #141822 !important;
}

.login-body-header-bg {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.08) 100%
  ) !important;
}
.login-close-icon-bg {
  background-color: #141822 !important;
}
.login-close-icon-color {
  color: #141822 !important;
}
.login-input {
  color: #141822 !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}
.login-input:focus {
  color: #141822 !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}
.login-input:hover {
  color: #141822 !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}
.login-input:active {
  color: #141822 !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
}
.login-input-2 {
  -webkit-box-shadow: 0 0 0px 500px #ffffff inset !important;
  box-shadow: 0 0 0px 500px #ffffff inset !important;
  -webkit-text-fill-color: #141822 !important;
}
.login-input-2:focus {
  -webkit-box-shadow: 0 0 0px 500px #ffffff inset !important;
  box-shadow: 0 0 0px 500px #ffffff inset !important;
  -webkit-text-fill-color: #141822 !important;
}

.login-body-input-2 {
  box-sizing: border-box !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  padding: 0.6rem !important;
}
.casino-color {
  color: rgba(20, 24, 34, 0.7) !important;
}

.casino-bg {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(147, 212, 255, 0.3) 100%
  ) !important;
}
.casino-games-header {
  border-radius: 4px 4px 0 0 !important;
  background: linear-gradient(
    270deg,
    rgba(0, 145, 255, 0.1) 0%,
    rgba(0, 145, 255, 0) 100%
  ) !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2) !important;
}
.casino-input {
  border-radius: 10px !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2) !important;
  border: 1px solid #fff !important;
}
.game-body-bg {
  background-color: #ffffff !important;
}
.casino {
  box-sizing: border-box;
  height: 283px;
}
.casino-header-img-bg {
  background: linear-gradient(
    90deg,
    rgba(25, 30, 41, 0) 0%,
    rgba(255, 255, 255, 0.8) 66.95%,
    #ffffff 100%
  ) !important;
}
.ant-modal-content-bg {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2) !important;
}
.game-body-bg-2 {
  background-color: #141822 !important;
}
.modal-header-bg {
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 100%
  ) !important;
}
.modal-middle-bg {
  background-color: #d0d9d0 !important;
}
.main-container-bg {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(147, 212, 255, 0.3) 100%
  ) !important;
}

.casher-header-bg {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 145, 255, 0.1) 100%
  ) !important;
}
.text-white-color {
  color: var(--text-color);
}
.text-white {
  color: var(--text-color) !important;
  text-align: center;
}
.casher-sub-main-bg {
  background-color: #ffffff !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2) !important;
  color: #141822 !important;
}
.casher-dropbtn {
  border: 1px solid #dfdfdf !important;
  border-radius: 4px !important;
  background-color: #f8f8f8 !important;
  color: #141822 !important;
}
.payment-main-bg {
  background-color: #ebebeb !important;
}
.casher-card-bg {
  background: rgba(255, 255, 255, 0) !important;
}

.casher-table-header {
  background-color: #ebebeb !important;
  color: #141822 !important;
}
.input-bg {
  background-color: #f8f8f8 !important;
}
.profile-section-bg {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 20%) !important;
}
.profile-section-input {
  box-shadow: 0 0 0px 500px #f8f8f8 inset !important;
  -webkit-text-fill-color: #141822 !important;
}
.table-cell {
  background-color: #ebebeb !important;
  color: #141822 !important;
}
.term-condition-main {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(147, 212, 255, 0.3) 100%
  ) !important;
  color: #141822 !important;
}

.term-links-bg {
  background: #ffffff !important;
  color: #141822 !important;
}
.input-left-bg {
  border: 1px solid #dfdfdf !important;
  border-radius: 4px !important;
  background-color: #f8f8f8 !important;
  color: #a3a3a3 !important;
}
.light-input-color {
  color: #141822 !important;
}
/* .ant-skeleton{
  background: #191e29 !important;
} */
.ant-menu-item-selected .ant-menu-title-content .menu-heading {
  font-weight: 600;
}
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.control-dots {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  position: absolute !important;
  z-index: 100 !important;
  bottom: 5px !important;
  left: 0 !important;
  right: 0 !important;
  background-color: #171717 !important;
  padding: 4px !important;
  border-radius: 10px !important;
  margin: 0 auto !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}
.carousel .control-dots .dot {
  margin: 0 5px !important;
  cursor: pointer;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
}

.card-selected img {
  filter: var(--side-menu-img);
}
