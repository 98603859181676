.mobileLogged-main {
  position: absolute;
  left: 0%;
  top: 0;
  width: 100vw;
  height: 100vh;
  color: var(--text-color);
  background-color: var(--sidebar-main-container-bg);
}

.ModalloginOpen {
  animation: openloginModal 0.4s ease-out forwards;
}

@keyframes openloginModal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mobile-footer-sidebar {
  position: absolute;
  margin-top: 5rem;
  width: 100%;
}

.mobileLogged-container {
  background-color: var(--navbar-mobile-bg-clr);
  width: 100%;
  height: 100%;
  position: relative;
}

.mobileLogged-header {
  position: relative;
}

.mobileLogged-pathImg-container {
  position: absolute;
  right: 10px;
  top: 20px;
  background-color: var(--navbar-mobile-bg-clr);
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.mobileLogged-client-container {
  margin: 20px;
}

.mobileLogged-client {
  font-size: 14px;
  font-weight: bold;
}

.mobileLogged-client-balance {
  color: var(--text-color);
  font-size: 10px;
  text-align: center;
}

.loggedIn-listName {
  padding: 10px;
}

.mobileLogged-menu-container {
  margin-bottom: 150px;
  margin-top: 10px;
  padding: 0 5px;
}

.mobileLogged-footer {
  margin: 15px;
  /* position: absolute; */
  /* bottom: 5px; */
}
.sideBar-border-mobile,
.sideBar-border-mobile-footer {
  position: unset !important;
  bottom: unset !important;
}
.sideBar-border-mobile-footer {
  height: 2px !important;
}

.mobileLogged-logOut {
  margin-left: 10px;
  font-family: Poppins;
  font-size: 14px;
}

.rotate_90-right {
  transform: rotate(-90deg);
}

a.navbar-rightside-section-mobile {
  color: var(--text-color);
  text-decoration: none;
}
