.dark-container {
  padding: 20px;
  margin-top: 20px;
}

.text-green {
  color: #7cf102;
}

.with-title {
  font-size: 18px;
  text-transform: capitalize;
}

.unused {
  color: #c10000;
  font-size: 12px;
}

.balance-container {
  padding-bottom: 10px;
}

.bln-brd {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.gra-brd {
  margin-top: 5px;
  height: 2px;
  width: 80%;
  background: linear-gradient(270deg, #00aaff, #141822);
}

.total {
  padding-top: 20px;
}
.amount-input-currency {
  border: 0px;
  border-radius: 4px;
  padding: 3px 0px !important;
  font-size: 14px;
  outline: none;
}
.amount-input {
  border: 0px;
  border-radius: 4px;
  background: var(--payment-input-bg);
  padding: 10px 0px;
  font-size: 14px;
  outline: none;
  padding-left: 10px;
  border: var(--payment-input-border);
}

.amount-input::placeholder {
  color: #67696f;
}

.amount-btn {
  background: linear-gradient(270deg, #7270ff, #00aaff);
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  width: 40%;
  cursor: pointer;
}

.with-input-w-sm {
  width: 57%;
}

.with-input-w {
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: none;
}
.ant-select-selector:active {
  border-color: none;
}

.right-section::-webkit-scrollbar {
  width: 5px;
}

.right-section::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #28304c;
}

.right-section::-webkit-scrollbar-thumb {
  background-color: white;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

@media screen and (max-width: 666px) {
  .amount-input {
    padding: 5px;
  }
  .withdraw-select_payment {
    display: none;
  }

  .withdraw-fontSize_12 {
    font-size: 12px;
  }

  .with-title {
    font-size: 14px;
  }

  .text-green {
    font-size: 12px;
  }

  .dark-container {
    margin-top: 0;
  }
  .amount-btn {
    padding: 8px;
  }
}
