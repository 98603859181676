.mail-modal {
  background: var(--deposit-main-bg);
  border-radius: 4px;
}

/* .mail-modal .mail-modal-header {
    border-radius: 4px 4px 0 0;
    background:var(--right-section-sub-main);
} */

.mail-modal .mail-modal-header h1 {
  height: 95px;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--Roboto);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 28px;
}

.mail-body h1 {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
}

.mail-body h2 {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
}

.mail-body input {
  border-radius: 4px;
  background: var(--payment-input-bg);
  border: var(--payment-input-border);
}
.mail-body textarea {
  border: var(--payment-input-border);
}

.mail .ant-input:placeholder-shown {
  color: #67696f !important;
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
}

.mail-body button {
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 17px;
}

.mail-body input {
  height: 40px;
  color: var(--text-color);
}

.mail-body textarea {
  height: 183px;
  color: var(--text-color);
}

@media screen and (max-width: 500px) {
  .newticket {
    position: unset;
  }
}
