.mobileNav-container {
  display: none;
}

@media screen and (max-width: 1000px) {
  .mobileNav-container {
    display: block;
    padding-bottom: 1px;
    background: linear-gradient(270deg, #181b25 30%, #00aaff 60%);
    font-family: "Poppins";
  }

  .mobileNav-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    padding: 32px 0;
    background: var(--navbar-mobile-light-bg);
  }

  .mobileNav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .mobileNav-img {
    width: 30px;
    height: 30px;
  }

  .active {
    color: var(--text-color);
    text-decoration: none;
  }

  .inactive {
    color: #92959a;
    text-decoration: none;
  }
}

@media screen and (max-width: 480px) {
  .mobileNav-main {
    font-size: 12px;
  }
}
