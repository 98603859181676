.main-section {
  padding-top: 5px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 666px) {
  .main-section{
    display: flex;
    flex-direction: column;
  }
  
}
