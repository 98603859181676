.show-btn {
  margin-right: 18px;
  margin-top: 20px;
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  padding: 10px 40px;
}

.bonus-custom-select {
  padding: 5px 0;
  width: 193px;
  border-radius: 4px;
  background-color: var(--payment-input-bg);
  color: var(--text-color);
  margin-right: 50px;
  margin-top: 7px;
  border: var(--payment-input-border) !important;
}
.bonus-form-main {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.ant-select-arrow {
  color: var(--text-color);
}

.bonus-form-container {
  display: flex;
}

.title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04px;
  cursor: pointer;
}

.bonus-titles {
  display: flex;
  padding: 5px 10px;
}

.text-white {
  color: var(--text-color);
}

.text-gray {
  color: var(--deposit-nav-text-clr);
}

.title-sportbook {
  margin-left: 15px;
}

.inp-btn-box {
  margin-top: 7px;
  width: 345px;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--payment-input-bg);
  margin-left: 27px;
  margin-right: 70px;
  border: var(--payment-input-border);
}

.bns-input {
  border: none;
  color: var(--text-color);
  background-color: var(--payment-input-bg);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.04px;
  padding-left: 13px;
  outline: none;
}

.activate-btn {
  height: 32px;
  width: 144px;
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  border: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04px;
}

.info-tooltip {
  margin: 10px 0px !important;
}

.info-tooltip-text {
  font-weight: bold;
  padding: 10px;
}

.nav-navEl {
  justify-content: space-between;
  text-align: center;
}

.title-border-nav {
  height: 2px;
  width: 100%;
  background-color: #0091ff;
  display: none;
  margin-top: 5px;
}

.downArrow-nav-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-navEl {
    width: 50%;
  }

  .title {
    font-size: 14px;
  }

  .activate-btn {
    font-size: 12px;
    height: 26px;
    width: 110px;
  }

  .bns-input {
    font-size: 12px;
  }

  .inp-btn-box {
    width: 270px;
  }

  .show-btn {
    font-size: 12px;
    padding: 7px 20px;
  }

  .bonus-custom-select {
    width: 120px;
    font-size: 13px;
    height: 40px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .downArrow-nav-mobile {
    display: block;
  }
}

@media screen and (max-width: 666px) {
  .title {
    padding-left: 0;
    width: 50%;
  }
  .title-bonus {
    width: 35% !important;
  }
  .nav-navEl {
    width: 100%;
    font-size: 15px;
    /* padding: 0 10px;
    padding-top: 10px; */
  }

  .info-accounts {
    color: var(--text-color);
  }

  .histUrl-column {
    flex-direction: column;
  }

  .histUrl-container {
    width: 100%;
  }

  .title-border-nav {
    display: block;
  }

  .nav-main-container {
    flex-direction: column-reverse;
    align-items: flex-end !important;
    /* padding: 6px; */
  }
}

@media screen and (max-width: 500px) {
  .title {
    padding-left: 0;
    width: 50%;
  }
  .title-bonus {
    width: 35% !important;
  }
  .downArrow-nav-mobile {
    display: block;
  }

  .rotate90 {
    transform: rotate(-90deg);
  }

  .rotate-90 {
    transform: rotate(90deg);
  }
  .nav-El-respnose {
    width: 590px;
  }
  .nav-main-container {
    overflow-x: scroll;
    padding-bottom: 0;
  }
  .nav-main-container::-webkit-scrollbar {
    display: none;
  }

  /* .none500{
    display: none;
  } */
}

@media screen and (max-width: 426px) {
  .bonus-form-main {
    flex-direction: column;
    gap: 0;
    padding: 0 10px;
  }

  .bonus-form-container {
    flex-direction: column;
    width: 100%;
  }

  .bonus-form-subc {
    display: flex;
    flex-direction: column;
  }

  .bonus-titles {
    justify-content: space-between;
  }

  .title-sportbook {
    margin-left: 0px;
    text-align: end;
  }

  .inp-btn-box {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
    margin-bottom: 15px;
  }

  .title {
    padding-left: 0;
    width: 50%;
  }
  .title-bonus {
    width: 35% !important;
  }

  .underline {
    padding: 1px;
    background: #0091ff;
    width: 100%;
  }

  .show-btn {
    margin: 0;
    padding: 10px 40px;
  }

  .bonus-custom-select {
    width: 100%;
    margin-bottom: 20px;
  }
  .nav-El-respnose {
    width: 590px;
  }
  .nav-main-container {
    overflow-x: scroll;
    padding-bottom: 0;
  }
  .nav-main-container::-webkit-scrollbar {
    display: none;
  }

  .down-arrow {
    display: none;
  }
}
