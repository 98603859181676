.title {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04px;
  padding-left: 30px;
}

.dropbtn {
  padding: 10px 13px;
  width: 193px;
  background-color: var(--payment-input-bg);
  color: var(--text-color);
  font-family: Roboto;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 60px;
  margin-top: 0px;
  border: var(--payment-input-border)
}

.dropdown {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  margin-left: 30px;
  width: 193px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--payment-input-bg);  
  min-width: 193px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  
}

.opt {
  color: var(--text-color);
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.opt:hover {
  background: linear-gradient(270deg, #7270ff, #00aaff);
}

.opt-open {
  display: block;
}

.dropdown:hover .dropbtn:hover {
  background: linear-gradient(270deg, #7270ff, #00aaff);
}

.show-btn {
  margin-right: 18px;
  margin-top: 20px;
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 16px;
  text-align: center;
  padding: 10px 40px;
  cursor: pointer;
}

@media screen and (max-width: 666px) {
  .show-btn-container {
    width: 100%;
    padding-top: 40px;
    padding-left: 25px;
  }

  .title {
    font-size: 15px !important;
  }

  .title_btn-container {
    margin-left: -25px;
  }

  .dropbtn {
    width: 70%;
    padding: 5px 15px;
  }
  .dropdown {
    margin-left: 0px;
  }

  .dropdown-content {
    min-width: auto;
    width: 40%;
  }
  .title_btn-container {
    display: flex;
    align-items: center;
    align-content: center;
  }
}
