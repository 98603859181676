.term-main-container {
  padding: 30px;
  background: var(--home-background);
}

.term-title {
  padding: 33px;
  font-size: 24px;
  color: var(--text-color);
  background: var(--right-section-sub-main);
}

.term-links {
  background: var(--right-section-bg);
  padding: 30px 33px;
  color: var(--text-color);
  display: flex;
  gap: 100px;
  border-bottom: 1px solid var(--home-background);
}

.term-li {
  color: #0091ff;
  text-decoration: underline;
  cursor: pointer;
  line-height: 19px;
}

.term-terms {
  background: var(--right-section-bg);
  padding: 30px 33px;
  color: var(--text-color);
}

.term-terms-title {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: 600;
}
@media only screen and (max-width: 480px) {
  .term-links,
  .term-terms {
    padding: 30px 10px;
  }
  .term-main-container {
    padding: 5px;
  }
}
