.nav-border {
  background: linear-gradient(270deg, #7270ff, #00aaff);
  width: 3px;
  height: 58px;
  box-shadow: 0 0 10px #537fff;
}
.info-desktop {
  display: none !important;
}
.nav-main {
  padding: 16px 33px;
  height: 58px;
  background: var(--navbar-sidebar-bg);
  font-size: 20px;
  font-family: var(--Poppins);
  width: 100%;
}

.nav-main-sub {
  display: flex;
  align-items: center;
  gap: 20px;
}
.nav-title {
  /* color: var(--text-color); */
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 22px;
}
.nav-btn {
  transform: rotate(90deg);
  cursor: pointer;
}
.img-color {
  filter: var(--mail-img);
}

.nav-img {
  width: 20px;
}

.gap-navBar {
  margin-right: 8px;
}

.dropDown-navMobile {
  width: 100%;
  position: relative;
  display: none;
}
.card-img-active, .mobile-finance-sidebar-img {
  filter: var(--side-menu-img);
}
img.nav-img.gap-navBar.mobile-finance-sidebar-img {
  width: 18px;
}
.nav-dropBtn {
  height: 48px;
  padding: 5px 20px;
  background: var(--navbar-sidebar-bg);
  font-size: 16px;
  font-family: var(--Poppins);
  width: 100%;
  color: var(--text-color);
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
}
.img-filter {
  filter: invert(1);
}
.nav-dropContent {
  /* position: absolute; */
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.08)
  );
  color: var(--text-color);
  width: 100%;
  padding: 8px 22px;
  margin-bottom: 2px;
  gap: 12px;
}

.dropNone {
  display: none;
}

@media screen and (max-width: 1130px) {
  .nav-main {
    width: 800px;
    overflow: scroll;
  }

  .nav-main::-webkit-scrollbar {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .nav-main {
    font-size: 14px;
    padding: 10px 20px;
  }
  .nav-main-sub {
    gap: 0;
  }

  .nav-img {
    width: 15px;
  }

  .navBar-downArrow {
    padding: 15px 0;
  }

  .gap-navBar {
    margin-right: 5px;
  }

  .nav-border {
    height: 57px;
  }
}

@media screen and (max-width: 720px) {
  .gap-responsive {
    margin-right: 10px;
  }
}
@media screen and (max-width: 480px) {
  .account-info-mobile-view {
    display: none !important;
  }
  .info-desktop {
    display: flex !important;
  }
}

@media screen and (max-width: 1000px) {
  .nav-border {
    height: 48px;
  }

  .dropDown-navMobile {
    display: block;
  }

  .nav-main {
    display: none;
  }

  .dropShow {
    display: flex;
  }

  .nav-img {
    margin-right: 10px;
  }


  .flex {
    align-items: flex-start;
  }
}

.down-arrow-in-mobile {
  visibility: hidden;
}
