.right-section {
  background: var(--right-section-bg);
  width: 30%;
  height: 100%;
  padding: 30px;
  overflow: auto;
  border-radius: 4px;
  box-shadow: var(--deposit-box-shadow);
}

@media screen and (max-width: 768px) {
  .right-section {
    width: 100%;
    height: fit-content;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 #000000;
  }
}

@media screen and (max-width: 666px) {
   .right-section{
    padding: 10px;
    box-shadow: none;
   }
}
