.main-support {
  background: var(--home-background);
  color: white;
  width: 100%;
  height: auto;
}

.title-main-support {
  font-size: 50px;
  color: var(--text-color);
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 35px;
  padding: 50px 0 30px 65px;
}

.support-right {
  background-color: var(--right-section-bg);
  margin-bottom: 50px;
  margin-left: 20px;
}

.asked-questions {
  font-size: 24px;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  background-color: #101219;
  padding: 33px;
  width: 100%;
}

.main-support .ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav {
  margin: 0;
  background-color: var(--support-header);
  padding: 0 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
  border-radius: 4px 4px 0 0;
}

.main-support .ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: none;
}
.main-support .ant-tabs-content-holder {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
  border: 4px;
}
.main-support .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--text-color);
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-shadow: none;
}
.main-support .ant-tabs {
  color: var(--footer-text-clr);
  font-family: Poppins;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}
@media screen and (max-width: 1020px) {
  .title-main-support {
    font-size: 30px;
    padding: 50px 0 30px 15px;
  }
}
@media screen and (max-width: 500px) {
  .title-main-support {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.05px;
    line-height: 24px;
    text-align: center;
    padding: 20px;
  }
}
