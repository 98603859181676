.date-dropbtn {
  padding: 12px 13px;
  background-color: var(--ant-dropdown-bg);
  color: var(--text-color);
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.date-dropdown-content {
  display: none;
  background-color: var(--payment-input-bg);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 150px;
  overflow-y: auto;
  position: relative;
  z-index: 100;
}

.date-opt {
  color: var(--text-color);
  text-decoration: none;
  display: block;
  cursor: pointer;
  padding: 10px 20px 10px 10px;
}

.text-right {
  text-align: right;
}

.date-opt:hover {
  background: linear-gradient(270deg, #7270ff, #00aaff);
}

.date-opt-open {
  display: block;
}

.date-dropdown:hover,
.date-dropbtn:hover {
  background: linear-gradient(270deg, #7270ff, #00aaff);
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.text-center {
  text-align: center;
}

.max-h {
  max-height: 45px;
}

@media screen and (max-width: 666px) {
  .date-dropbtn{
    padding: 5px 10px;
  }
}
