.withdraw-main {
  padding-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 666px) {
  .withdraw-main{
    display: flex;
    flex-direction: column;
  }
}
