.prof-r-section {
  background:  var(--right-section-bg);
  width: 37%;
  height: 100%;
  overflow: auto;
}

.prof-r-title {
  background: var(--right-section-bg);
  width: 100%;
}

.prof-r-pass-text {
  color: #909298;
  margin-top: 32px;
}
.profile-right-section{
  background: var(--deposit-main-bg);
  box-shadow: var(--deposit-box-shadow);
}
.set-change-btn {
  margin-top: 60px;
  margin-bottom: 30px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  width: 100%;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.r-nav {
  padding: 26px 33px;
}

.r-resp {
  height: 76px;
}

.r-mt-120 {
  margin-top: 170px;
}

.changePassword-container{
  width: 100%;
  text-align: center;
}

.self-exclusion{
  width: 100%;
}

.self-exclusion-mobile{
  display: none;
}

@media screen and (max-width: 768px) {
  .prof-r-hidden {
    /* display: none; */
  }

  .prof-r-section {
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px 0 #000000;
  }

  .r-mt-120 {
    margin-top: 20px;
  }

  .none-mobile{
    display: none;
  }

  .password-section{
    border-radius: 4px;
    background:  var(--right-section-bg);
  }

  .changePassword-container{
    display: none;
  }

  .prof-r-pass-text, .set-change-btn{
    margin-top: 10px;
  }

  .set-main{
    margin-top: -28px;
  }

  .self-exclusion{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 666px) {
  .self-exclusion{
    display: none;
  }

  .self-exclusion-mobile{
    display: block;
    text-align: left;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    margin-top: -35px;
  }

  .prof-r-section{
    box-shadow: none;
  }

  .resp-game-main{
    margin-top: -30px;
  }
}

@media screen and (max-width: 478px) {
  .prof-r-pass-text{
    font-size: 9px;
  }
}
