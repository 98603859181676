.edit {
  color: #0091ff;
  text-decoration: underline;
}

.profile-container {
  display: flex;
  align-self: start;
  justify-content: space-between;
}

.profile-main-section {
  width: 80%;
  background: var(--deposit-main-bg);
  height: 100%;
  padding: 15px 0;
  color: var(--text-color);
  height: 100%;
  padding: 29px 0;
  box-shadow: var(--deposit-box-shadow);
}

.profile-input {
  width: 100%;
  border: 0px;
  border-radius: 4px;
  background: var(--payment-input-bg);
  border: var(--payment-input-border);
  padding: 12px;
  font-size: 14px;
  outline: none;
}

.profile-input::placeholder {
  color: #67696f;
}

.password-section input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 500px #272a33 inset;
  -webkit-box-shadow: 0 0 0px 500px #272a33 inset;
  -webkit-text-fill-color: var(--white);
}

.password-section .ant-alert {
  padding: 0;
  background: transparent;
  border: 0;
}
.password-section .ant-alert-message {
  color: red;
}

.padding-left {
  padding: 0 35px;
}

.mobile-border {
  display: none;
}

.main-section-shadow {
  box-shadow: none;
}

.set-canc-btn-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .profile-main-section {
    width: 100%;
    border-radius: 15px;
    padding-top: 15px;
  }

  .profile-container {
    flex-direction: column;
    border-radius: 15px;
  }
}

@media screen and (max-width: 666px) {
  .mobile-border {
    display: block;
    height: 1px;
    width: 90%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0 auto;
  }

  .main-section-shadow {
    box-shadow: inset 5px 20px 15px -23px rgba(0, 0, 0, 0.85);
    padding-top: 10px;
    border-radius: 5px;
  }

  .respGame-col {
    flex-direction: column;
  }

  .set-canc-btn {
    display: none;
  }

  .set-canc-btn-mobile {
    display: block;
  }

  .mobile-mt {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15px;
  }
}

@media screen and (max-width: 478px) {
  .settingSelect {
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    margin-top: 0px !important;
  }

  .padding-left {
    padding: 0 10px;
  }
  .profile-main-section {
    padding: 15px 0 0 0;
    border-radius: 0px;
  }
  .profile-container {
    gap: 0px;
  }
}
