.casino-info-model .model-header {
  height: 95px;
  border-radius: 4px 4px 0 0;
  background: var(--modal-background);
  padding: 1.8rem 1.5rem;
}
.casino-game-modal-iframe {
  height: 900px;
}
@media screen and (max-width: 1920px) {
  .casino-game-modal-iframe {
    height: 600px;
  }
}
@media screen and (max-width: 1440px) {
  .casino-game-modal-iframe {
    height: 550px;
  }
}
@media screen and (max-width: 1280px) {
  .casino-game-modal-iframe {
    height: 500px;
  }
}
@media screen and (max-width: 1024px) {
  .casino-game-modal-iframe {
    height: 450px;
  }
}
@media screen and (max-width: 480px) {
  .casino-game-modal-iframe {
    height: 400px;
  }
  .casino-play-now .resize-btn {
    top: 5px;
    right: 18px;
  }
}
.casino-info-model .model-header h1 {
  color: var(--text-color);
  font-family: var(--Poppins);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 35px;
}

.casino-info-model .model-footer {
  height: 158px;
  border-radius: 4px 4px 0 0;
  background: var(--modal-background);
  padding: 2rem 1.5rem;
}

.casino-info-model .model-footer h3 {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05px;
  line-height: 24px;
}

.casino-info-model .model-footer p {
  color: var(--footer-text-clr);
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
}

.casino-info-model .model-body h2 {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.05px;
  line-height: 24px;
}

.casino-info-model .model-body .td-left {
  color: rgba(20, 24, 34, 0.7);
  font-family: var(--Roboto);
  font-weight: bold;
  font-size: 17px;
  letter-spacing: 0.04px;
  line-height: 16px;
}

.casino-info-model .model-body .td-right {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.04px;
  line-height: 16px;
  padding: 0.4rem 1rem;
}

.casino-info-model .model-body a {
  color: #0091ff;
  font-family: var(--Roboto);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 19px;
}

.game-card-body .info-model {
  cursor: pointer;
}

body::-webkit-scrollbar {
  display: none !important;
}

.sider-menu::-webkit-scrollbar {
  display: none !important;
}

.game-card-body .info-model {
  cursor: pointer;
}

.casino-info-model .model-background::after {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: blur(10px);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.demo-click {
  color: #0091ff !important;
  cursor: pointer;
  font-family: var(--Roboto);
  font-size: 17px;
  letter-spacing: 0;
  line-height: 19px;
}
.non-blurred {
  position: relative;
  z-index: 1;
}
.info-modal-background {
  background: var(--info-modal-background);
}

.ant-modal-header {
  background-color: var(--sidebar-bg);
  border-bottom: none;
  padding: 1.2rem 0;
}
.ant-modal-wrap {
  overflow: unset;
  /* top: -50px; */
}

.casino-game-modal .game-modal-header {
  padding: 2rem 0;
}

.game-modal-header h1 {
  color: var(--text-color);
  font-family: var(--Poppins);
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.game-modal-header p {
  color: var(--text-color);
  font-family: var(--Poppins);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0 1rem;
}

.casino-play-now .ant-modal-close-x {
  line-height: 45px;
  color: var(--text-color);
}

.casino-play-now .resize-btn {
  position: absolute;
  top: 5px;
  right: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: var(--mail-img);
}
.info-modal-mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .ant-modal-header {
    padding: 2.2rem 0;
  }
  .game-modal-header h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 500px) {
  .info-modal {
    position: unset;
  }

  .info-modal-desktop {
    display: none;
  }
  .info-modal-mobile {
    display: block;
  }
  .casino-info-model .model-header {
    height: 57px;
    padding: 0.8rem 1.5rem;
  }
  .info-modal-game-name h1 {
    color: var(--text-color);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    padding: 1rem 0;
    line-height: 21px;
    text-align: center;
    margin-bottom: 0;
  }
  .info-modal-body img {
    height: 86px;
    width: 100%;
  }
  .info-play-btn {
    height: 36px;
    width: 100%;
    box-shadow: 0 2px 19px 0 rgba(0, 145, 255, 0.5);
  }
  .info-border-gradient {
    background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
    border: 0;
    color: var(--text-color);
    border-radius: 4px;
  }
  .info-border-gradient-1 {
    background: var(--login-background);
    border: 1px solid transparent;
    border-radius: 4px;
    color: var(--text-color);
    margin-top: 10px;
  }
  .info-table {
    padding: 20px 20px;
  }
  .info-table h3 {
    color: var(--text-color);
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.04px;
    line-height: 16px;
  }
  .info-table .td-left {
    color: var(--footer-text-clr);
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.03px;
    line-height: 14px;
  }
  .info-table .td-right {
    color: var(--text-color);
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.03px;
    line-height: 14px;
  }
  .games-hover {
    display: none;
  }
}
