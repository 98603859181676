logo-div {
  /* padding-left: 24px; */
  margin-top: 25px;
}
.sub-title {
  /* color: #808185; */
  font-family: "Poppins";
  font-style: normal;
  font-size: 15px;
  text-transform: capitalize;
}
.ant-result
  .ant-result-icon
  .ant-result-title
  .ant-result-subtitle
  .ant-result-extra {
  background-color: #ffffff !important;
}
.sider-heading {
  position: absolute;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: 900;
  color: #808185;
}
.ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.08);
}
.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: var(--side-menu-selected-name);
}

.ant-menu-item:focus-visible {
  box-shadow: none !important;
}
.ant-menu-inline > .ant-menu-item {
  height: 60px;
  line-height: 30px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--side-menu-selected);
  text-align: center;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  border-right: 0;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: rgba(255, 255, 255, 0.08);
}
.menu-item-style {
  display: grid !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80px !important;
  padding-left: 15px !important;
}
.menu-img {
  display: table;
  margin: 0 auto;
}

.active-menu-img {
  filter: var(--side-menu-img-drop-shadow);
}
.active-menu-img-light {
  filter: var(--side-menu-img);
}
.main-layout-margin {
  margin-left: 120px;
}

@media screen and (max-width: 1000px) {
  .main-layout-margin {
    margin-left: 0;
  }
}

.ant-layout-sider-children {
  background-color: var(--sidebar-bg);
}
.ant-menu {
  background-color: unset !important;
}
#sidebar-ul {
  width: 120px;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  font-family: Poppins;
  color: #a3a3a3;
}

.ant-menu-light .ant-menu-item:hover {
  color: var(--side-menu-selected-name);
  background-color: var(--side-menu-selected);
  border-right: 3px solid #1890ff;
  font-weight: 600;
}
.ant-menu-item:hover .menu-img {
  filter: var(--side-menu-img);
}
