.games-card-header {
  color: var(--text-color);
  font-family: var(--fontFamilyPopins);
  font-size: var(--fontSiz-14);
  letter-spacing: var(--letterSpacing-0);
  line-height: var(--lineHeight-16);
  text-align: var(--text-center);
}
.info-model {
  color: var(--footer-text-clr);
}

.games-card-horizontal-line {
  width: 50%;
  height: 1.5px;
  background: var(--blue-line);
  margin: 10px auto;
}

.gamesCard_img {
  background-size: cover;
}
