* {
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --Roboto: "Roboto";
  --Poppins: "Poppins";
  --secondary-blue: #16a0ff;
}
.login-body-input,
.login-body-input input {
  box-sizing: border-box;
  border: 1px solid #303238;
  border-radius: 0px;
  background-color: #202229;
  padding: 0.6rem;
  box-shadow: 0 0 0px 500px #202229 inset !important;
}

.login-body-input input {
  color: #848589;
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
  box-shadow: 0 0 0px 500px #202229 inset !important;
}
.login-body-input .ant-input-affix-wrapper > input.ant-input:focus:focus {
  background-color: #202229;
  color: #848589;
  box-shadow: 0 0 0px 500px #202229 inset !important;
}

.ant-modal-content {
  border-radius: 5px;
}

body {
  font-family: var(--Roboto), sans-serif;
  background: #1111;
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-close-x {
  color: var(--text-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 500px #202229 inset;
  box-shadow: 0 0 0px 500px #202229 inset;
  -webkit-text-fill-color: var(--white);
}

.login .ant-input-affix-wrapper > input.ant-input {
  color: var(--white);
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login .ant-input:placeholder-shown {
  color: #848589 !important;
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login-header {
  height: 95px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-body {
  background: black;
  border-radius: 0 5px 5px 0;
}

.login-input-box {
  background-color: #202229 !important;
  border: 1px solid #303238 !important;
  color: #848589 !important;
  box-shadow: 0 0 0px 500px #202229 inset !important;
}

.login-body button {
  padding: 1rem;
  border-radius: 6px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 17px;
  border: none;
}

.login
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background: #202229;
}

.hr-line hr {
  box-sizing: border-box;
  height: 1px;
  /* width: 87px; */
  border: 1px solid #303238;
}

.forgot-password a {
  color: var(--secondary-blue);
}

.social-medium-icons img {
  cursor: pointer;
}

.hr-line span {
  padding: 0 1.2rem;
  color: #848589;
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login-body p {
  text-align: center;
  color: #848589;
  font-size: 11px;
  letter-spacing: 0.03px;
  line-height: 12px;
}

.login-body h1 {
  color: var(--white);
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
  text-align: center;
}

.login-body-select {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #303238;
  border-radius: 4px;
  background-color: #202229;
}
.ant-select-selection {
  background-color: transparent !important;
}
.login-body-select .ant-select-selector span {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--navbar-bg);
  color: var(--text-color);
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login-body
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 2.5rem;
  border: none;
}

.login .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 2.5rem;
}

.login-body
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 0px;
}

.ant-select-arrow {
  z-index: 1;
  color: var(--white);
}

.register-custom-select-icon {
  z-index: 9;
  top: 10px;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: var(--white);
}

.register-body
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  padding: 0 1.8rem;
}

.login-body .ant-checkbox + span {
  color: #848589;
  font-size: 11px;
  letter-spacing: 0.03px;
  line-height: 13px;
  align-self: flex-start;
}

.login-body
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  padding: 5px 2rem;
  z-index: 1;
  color: var(--white);
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.65);
  padding-top: 80px;
}

.ant-steps-item-title::after {
  display: none !important;
}

.ant-steps-item-icon {
  width: 90px !important;
  border-radius: 0 !important;
}

.main-navbar {
  position: fixed;
  background: #181b25;
  width: calc(100% - 119px);
  height: 59px;
  top: 0;
  z-index: 999;
}

.main-navbar .navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-right .custom-btn,
.card-play-btn {
  padding: 0.5rem 1rem;
  -ms-transform: skewX(-15deg);
  transform: skewX(-15deg);
  color: var(--white);
  letter-spacing: 0.04px;
  font-size: 14px;
  line-height: 16px;
  background: #181b25;
  border-radius: 6px;
}

.navbar-right .border-gradient {
  background: linear-gradient(#181b25, #181b25) padding-box,
    linear-gradient(270deg, #7270ff 0%, #00aaff 100%) border-box;
  border: 1px solid transparent;
}

.navbar-right .border-gradient-1,
.card-play-btn {
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  border: 0;
}

.navbar-right .border-gradient-2 {
  border: 0;
  background-color: #272a33;
}

.main-navbar .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px solid;
  border-radius: 0;
}

.main-navbar .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  border-right-width: 0 !important;
  outline: none;
}

.custom-dropdown .ant-dropdown-menu {
  background-color: #101219;
  height: 300px;
  width: 187px;
}

.custom-dropdown .ant-dropdown-menu li {
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
  padding: 0.78rem 0;
  margin: 0 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.custom-dropdown .ant-dropdown-menu-item:hover {
  background-color: #101219;
}

.custom-dropdown .ant-dropdown-menu li a {
  text-decoration: none;
}

.custom-img-white img {
  filter: brightness(0) invert(1);
}

.jackpot-background img {
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  /* bottom: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 99; */
  margin: auto;
}

.jackpot-background h1 {
  position: absolute;
  top: 95px;
  left: 110px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: var(--white);
  font-family: ".SF NS Display";
  font-size: 70px;
  letter-spacing: 0;
  line-height: 83px;
  text-shadow: 0 2px 14px #13beff;
}

.jackpot-background p {
  color: var(--white);
  font-family: var(--Poppins);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 0;
}

.jackpot-header {
  height: 58px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
}

.jackpot-header .leftstyle {
  height: 58px;
  width: 3.09px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  box-shadow: 0 0 10px 0 #537fff;
}

.jackpot .jackpot-body {
  padding-top: 7rem !important;
}

.jackpot-header h1 {
  color: var(--white);
  font-family: var(--Poppins);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}

.jackpot-hr {
  height: 3px;
  width: 100%;
  background: linear-gradient(
    270deg,
    rgba(238, 238, 238, 0) 0%,
    var(--white) 49.72%,
    rgba(216, 216, 216, 0) 100%
  );
  box-shadow: 0 0 12px 0 rgba(255, 255, 255, 0.22);
}

.subscribe-body .subscribe-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.subscribe-body .subscribe-body-inner {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribe-body-inner h1 {
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.07px;
  line-height: 30px;
}

.subscribe-body-inner p {
  color: #b5b5b5;
  font-family: var(--Roboto);
  font-size: 15px;
  letter-spacing: 0.04px;
  line-height: 18px;
}

.subscribe-body-inner input {
  box-sizing: border-box;
  height: 54px;
  width: 100%;
  border: 1px solid #3d4855;
  border-radius: 6px;
  background-color: #293443;
}

.subscribe-body-inner button {
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 18px;
  height: 44px;
  width: 139px;
}

.subscribe-body-inner .custom-btn {
  top: 0;
  bottom: 0;
  right: 17px;
  /* left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.subscribe-body-inner input:placeholder-shown {
  padding: 0 1rem;
  color: #848589;
  font-family: var(--Roboto);
  font-size: 15px;
  letter-spacing: 0.04px;
  line-height: 18px;
}

.forgot-password a {
  color: var(--secondary-blue);
}

.mail-modal {
  background: #101219;
  border-radius: 5px;
}

.mail-modal .mail-modal-header {
  border-radius: 4px 4px 0 0;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.08) 100%
  );
}

.mail-modal .mail-modal-header h1 {
  height: 95px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--Roboto);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 28px;
}

.mail-body h1 {
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
}

.mail-body h2 {
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
}

.mail-body input {
  border-radius: 4px;
  background-color: #272a33;
}

.mail .ant-input:placeholder-shown {
  color: #67696f !important;
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
}

.mail-body button {
  height: 44px;
  border-radius: 6px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 17px;
}

.mail-body input {
  height: 40px;
  color: var(--white);
}

.mail-body textarea {
  height: 183px;
  color: var(--white);
  background: var(--payment-input-bg);
  border: 1px solid #3d4855;
}

/* .casino .header-img {
    background-image: linear-gradient(90deg, rgba(25, 30, 41, 0) 0%, rgba(25, 30, 41, 0.95) 66.95%, #191E29 100%), url('../Images/oceanbet-banner.svg');
    background-size: cover;
} */

.casino-header-body {
  background-color: #141822;
}

.casino-header-body h1 {
  color: var(--white);
  font-family: var(--Roboto);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 21px;
}

.casino-header-body h2 {
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
  margin-bottom: 0;
}

.casino-header-body p {
  color: rgba(255, 255, 255, 0.5);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 20px;
}

.casino-header-body h3 {
  color: red;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 20px;
  margin-bottom: 0;
}

.casino-header-body .custom-progress {
  transform: rotate(270deg);
  height: 136px;
  /* width: 5px;   */
  border-radius: 3px;
  /* background-color: #28304C; */
}

.casino-header-body img {
  margin-bottom: 1rem;
}

.casino-scroll {
  height: 200px;
  overflow: auto;
}

.casino-scroll::-webkit-scrollbar,
.game-body::-webkit-scrollbar {
  width: 5px;
}

.casino-scroll::-webkit-scrollbar-track,
.game-body::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #28304c;
}

.casino-scroll::-webkit-scrollbar-thumb,
.game-body::-webkit-scrollbar-thumb {
  background-color: #3f8aff;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.games {
  background-color: #181b25;
  padding: 2rem 0;
}

.casino-game {
  padding: 1rem 0;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
}

.casino-game h2 {
  color: #92959a;
  font-family: var(--Poppins);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 0;
}

.casino-game ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.casino-game img {
  height: 28px;
  width: 28px;
}

.casino-game input {
  border: 1px solid #303238;
  border-radius: 10px;
  background-color: #141822;
  height: 40px;
  width: 382px;
  color: #ffffff;
}

.casino-game .search-icon {
  color: #67696f;
}

.game-body {
  border-radius: 4px;
  background-color: #101219;
  margin: 1rem 1.5rem;
  /* height: 1000px; */
  /* overflow-y: auto; */
}

.game-body h1 {
  color: var(--white);
  font-family: var(--Poppins);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}

.game-body p {
  color: #8d9095;
  font-family: var(--Roboto);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
}

.game-body .ant-switch-checked:focus {
  box-shadow: none;
}

.game-card-body {
  height: 49px;
  width: 321px;
  border-radius: 0 0 4px 4px;
  background-color: rgba(16, 18, 25, 0.9);
}

.game-card-body h5 {
  color: var(--white);
  font-family: var(--Poppins);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

.game-card {
  overflow: hidden;
}

.game-card:hover .game-card-body {
  height: 100%;
}

.card-play-btn {
  -ms-transform: skewX(0deg);
  transform: skewX(0deg);
  width: 131px;
}

.cursor-pointer {
  cursor: pointer;
}

.casino-info-model {
  background-color: #141822;
}

.house-fun-container button span {
  transform: skewX(15deg) !important;
  -ms-transform: skewX(15deg) !important;
  display: inline-block !important;
}

html {
  scrollbar-width: none;
}

.sider-menu {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.casino-hr {
  background: var(--hr-line);
  height: 1px;
}

.casino-right-hr {
  background: linear-gradient(
    -45deg,
    #0091ff 30%,
    #0091ff 40%,
    #0c0c0c 60%,
    transparent
  );
  height: 1px;
}

@media screen and (max-width: 1000px) {
  .sider-menu {
    display: none;
  }
  .mail-modal .mail-modal-header h1 {
    height: 60px !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
}
