.contact-help{
    width: 100%;
}

.contact-help-title{
    font-size: 20px;
    font-weight: bold;
    padding: 0 30px;
}

.contact-help-container{
    padding: 30px;
    padding-bottom: 0;
}

.cont-text-help{
    width: 50%;
}

.social-help{
    padding:  30px;
}

.additional-info{
    font-size: 20px;
    font-weight: bold;
}