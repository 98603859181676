.footerPayment-img-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  gap: 1em;
}

.main-footer {
  padding-top: 25px;
  background-color: var(--sidebar-bg);
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
}

.main-footer li {
  cursor: pointer;
}

.custom-hr-line {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.14);
}

.custom-hr-right {
  border-right: 1px solid rgba(255, 255, 255, 0.14);
  height: 70px;
}

.main-footer p {
  margin-bottom: 0;
  color: #67696f;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
}

.main-footer img {
  margin-bottom: 5px;
}

.footer-links li {
  line-height: 2.5rem;
  color: var(--footer-text-clr);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.footer-links li:hover, li.active-clr {
  color: var(--text-color);
}

.footer-links .footer-links-header {
  color: var(--text-color);
}

.saferBetter {
  color:  var(--text-color);;
  font-size: 26px;
  font-weight: 900;
}

.playingResponsible {
  color: var(--safe-better-text);
  font-size: 13px;
}

.link-list-footer {
  text-decoration: none;
}

.footer-part2 {
  height: 100%;
  padding: 10px 0;
  opacity: 1;
}

.footer-downArrowGray {
  display: none;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.footer-links-title {
  display: flex;
  justify-content: space-between;
}

.footer-ul-list {
  list-style-type: none;
}

.footer-link-style {
  text-decoration: none;
}

.main-footer-container{
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px){
  .footer-ul-list{
    padding: 0;
  }

  .footer-infoText {
    font-size: 14px !important;
  }

  .main-footer-container{
    width: 100%;
  }

  .main-footer{
    padding: 0px 20px;
  }

  .footer-ul-list{
    padding: 0 5px;
  }

  .footerPayment-img{
    height: 14px;
  }

  .footer-links-header{
    font-size: 15px !important;
  }

  .footer-title-size{
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 670px) {
  .footerPayment-img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin: 5px;
  }
  .social-media-container {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center !important;
    width: 100%;
    gap: 15px !important;
    margin: 5px;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
  }

  .footerImages-social {
    height: 24px;
    width: 24px;
  }

  .links-list-none {
    display: none;
  }

  .icons-text,
  .pb-3 {
    font-size: 12px !important;
  }
  .footerPayment-img {
    height: 12px;
    margin-right: 5px;
  }

  .footer-downArrowGray {
    display: block;
  }
  .saferBetter {
    font-size: 15px;
  }
}
@media only screen and (max-width: 425px) {
  .saferBetter {
    font-size: 15px;
  }

  .playingResponsible {
    font-size: 10px;
  }

  .casino_2-img {
    height: 30px;
  }
}
