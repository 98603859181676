.balance-link {
  margin-left: 30px;
  text-decoration: none;
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.balance-link:hover {
  color: var(--text-color);
}

.balance-account {
  padding-right: 50px;
}
