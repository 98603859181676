.main-navbar {
  position: fixed;
  background: var(--navbar-bg);
  width: calc(100% - 119px);
  height: 59px;
  top: 0;
  z-index: 999;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.main-navbar .navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-right .custom-btn,
.card-play-btn {
  padding: 0.5rem 1rem;
  -ms-transform: skewX(-15deg);
  transform: skewX(-15deg);
  color: var(--navbar-right-text-clr);
  letter-spacing: 0.04px;
  font-size: 14px;
  line-height: 16px;
  background: var(--navbar-bg);
  border-radius: 6px;
  height: 32px;
}
/* #btn-text-clr {
  color: var(--text-c);
} */
.navbar-right .border-gradient {
  background: var(--login-background);
  border: 1px solid transparent;
}
#register-btn-clr {
  color: white;
}
.navbar-right .border-gradient-1,
.card-play-btn {
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  border: 0;
}

.navbar-right .border-gradient-2 {
  border: 0;
  background-color: var(--navbar-right-btn);
}

.main-navbar .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* background-color: var(--navbar-bg); */
  border: 0px solid;
  border-radius: 0;
}
.language-dropdown img {
  border-radius: 50%;
  height: 28px;
  width: 30px;
}
.main-navbar .ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  border-right-width: 0 !important;
  outline: none;
}

.custom-dropdown .ant-dropdown-menu {
  background-color: var(--right-section-sub-main);
  height: 300px;
  width: 187px;
}

.custom-dropdown .ant-dropdown-menu li {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
  padding: 0.78rem 0;
  margin: 0 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.logout {
  filter: var(--mail-img);
}

.custom-dropdown .ant-dropdown-menu-item:hover {
  background-color: var(--right-section-sub-main);
}

.custom-dropdown .ant-dropdown-menu li a {
  text-decoration: none;
}

.custom-img-white img {
  filter: var(--mail-img);
}

.list-inline-item button span {
  transform: skewX(15deg) !important;
  -ms-transform: skewX(15deg) !important;
  /* display: inline-block !important; */
}
.cursor-unset {
  cursor: unset !important;
}

.main-navbar-responsiveMenu {
  display: none;
}

.navBarlogIn-account {
  font-size: 12px;
  color: var(--text-color);
}

.navBarlogIn-name {
  color: var(--text-color);
  border: 2px solid #0091ff;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  margin-left: 5px;
}
.burger-menu img,
.user_2-menu img,
.side-arrow-mobile-menu img,
.info-accounts-img img {
  filter: var(--mail-img);
}

@media screen and (max-width: 480px) {
  .navbar-mobile-view {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .main-navbar {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .navbar-select,
  .list-inline {
    display: none;
  }
  .main-navbar-responsiveMenu {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-navbar {
    width: 100%;
    background-color: var(--navbar-mobile-light-bg);
    box-shadow: var(--navbar-mobile-light-shadow);
  }
  .sidebarLogo-navbar {
    height: 47px;
    width: 56px;
  }
}
@media screen and (max-width: 1300px) {
  .navbar-right .custom-btn,
  .card-play-btn {
    padding: 0.3rem 0.6rem;
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .navbar-right .custom-btn,
  .card-play-btn {
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
}
