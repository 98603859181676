.contactSocial-border {
  width: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
}
.contact-sup {
  padding: 30px;
  background-color: var(--right-section-bg);
}
.contact-sup-title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
  padding-bottom: 20px;
}
.cont-title {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 17px;
  margin-right: 10px;
}
.contact-boder-div {
  width: 2px;
}
.cont-text {
  color: var(--footer-text-clr);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 16px;
}
.blackIcon-container {
  padding: 10px 0;
}
.help-page {
  margin-top: 15px;
}
.help-page p {
  color: var(--footer-text-clr);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 17px;
  margin-bottom: 0;
}

.help-a {
  font-weight: 500;
  text-decoration: underline;
  color: #0091ff;
  cursor: pointer;
}
.contact-sup-subtitle {
  color: var(--footer-text-clr);
  padding-bottom: 20px;
}
.help-page-bottom {
  display: none;
}
.help-page-bottom p {
  color: var(--footer-text-clr);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 17px;
  margin-bottom: 0;
}

.help-a {
  font-weight: 500;
  text-decoration: underline;
  color: #0091ff;
  cursor: pointer;
}
.social-name {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  padding-top: 10px;
  line-height: 17px;
}
@media screen and (max-width: 1020px) {
  .contact-sup-container {
    padding: 10px;
  }
  .contact-sup-title {
    font-size: 16px;
    padding: 0;
  }
  .cont-title {
    font-size: 14px;
  }
  .cont-text {
    font-size: 14px;
  }
  .help-page p {
    font-size: 14px;
  }
  .help-a {
    font-size: 14px;
  }
  .contact-sup-subtitle {
    font-size: 14px;
  }
  .blue-icon-img {
    width: 28px;
    height: 28px;
  }
  .social-name {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .contact-boder-div {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .contact-sup {
    padding: 15px;
  }
  .contact-box {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
  }
  .contact-sup-title {
    font-size: 14px;
    letter-spacing: 0.04px;
    line-height: 16px;
    padding: 10px 0 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .blackIcon {
    padding: 15px 0 0;
  }
  .cont-title {
    color: var(--footer-text-clr);
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 14px;
  }
  .cont-text {
    color: var(--text-color);
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 14px;
  }
  .help-page {
    display: none;
  }
  .help-page-bottom {
    display: block;
  }
  .contact-sup-subtitle {
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 14px;
    padding: 15px 0;
  }
  .social-div {
    padding: 15px 0;
    margin: 0 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .help-page-bottom {
    padding: 15px 0 0;
  }
  .help-page-bottom p {
    font-size: 10px;
    letter-spacing: 0.03px;
    line-height: 11px;
  }
  .help-a {
    font-size: 10px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0.03px;
  }
  .social-name {
    font-size: 12px;
    letter-spacing: 0.03px;
    line-height: 14px;
  }
}
