/*===========
    Card
===========*/

.card {
  height: 100%;
  width: 100%;
  margin: 0 auto 10px auto;
}
@media screen and (max-width: 480px) {
  .card {
    margin: 0 auto 55px auto;
  }
}
.dark-bg {
  background: var(--right-section-bg);
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.radius-full {
  border-radius: 50%;
}

.card-noSelected {
  background: rgba(255, 255, 255, 0.02);
}

.card-selected {
  background: var(--card-bg-selected);
}

.brd {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.card-h {
  height: 300px;
}

.card-commission-h {
  height: 360px;
}

.border-selected {
  padding: 3px;
  background: linear-gradient(270deg, #00aaff, #7271ff);
  border-radius: 10px;
}

.border-noSelected {
  padding: 2px;
  background: lightGray;
  border-radius: 10px;
}

.card-first {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-bottom: 1px solid var(--footer-text-clr);
  padding: 0 20px;
}

.card-sphere {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-sphere-border-noSelected {
  background: gray;
  padding: 2px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card-sphere-border-selected {
  background: linear-gradient(270deg, #00aaff, #7271ff);
  padding: 2px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px #537fff;
}

.card-title {
  font-size: 20px;
  text-align: center;
  max-width: 200px;
}

.card-second {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 75%; */
  padding: 10px 0px;
}

.b-brd {
  border-bottom: 1px solid var(--footer-text-clr);
}

.sub {
  font-size: 18px;
}

.card-selected-img {
  position: absolute;
  top: 8px;
  right: 10px;
}

.max-w-8 {
  max-width: 35px;
}
.mobile-nav1 {
  display: none;
}

@media screen and (max-width: 666px) {
  .card-title,
  .card-second {
    font-size: 12px;
  }

  .brd {
    height: 225px;
  }
  .sub {
    font-size: 15px;
  }
}
@media screen and (max-width: 480px) {
  .mobile-nav1 {
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }
  .mobile-nav2 {
    display: none;
  }
  .card-selected-img {
    position: initial;
  }
  .card-second {
    flex-direction: row-reverse !important;
    justify-content: space-between;
    padding: unset;
  }
  .card-noSelected {
    padding: 0px 10px;
  }
  .card-selected,
  .card-noSelected {
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
  }
  .brd {
    height: 105px;
  }
  .card-sphere-border-selected,
  .card-sphere-border-noSelected {
    height: 35px;
    width: 35px;
  }
  .card-first {
    height: auto;
    width: auto;
    border: none;
    padding: 0px 10px;
  }
  .container-2 {
    width: 70%;
  }
  .logo-container {
    flex-direction: column;
    /* height: 270px; */
  }
}
