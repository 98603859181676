.login-lable {
  display: none;
}
/* .ant-input-affix-wrapper>input.ant-input:focus {
    box-shadow: 0 0 0px 500px #202229 inset !important;
} */

.login .ant-input-affix-wrapper > input.ant-input {
  color: var(--white);
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login .ant-input:placeholder-shown {
  color: #848589 !important;
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login .ant-input-recovery-wrapper > input.ant-input {
  color: var(--text-color);
  line-height: 14px !important;
}
.recovery-email {
  font-weight: 400;
  color: var(--text-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 500px #202229 inset;
  box-shadow: 0 0 0px 500px #202229 inset;
  -webkit-text-fill-color: var(--text-color);
}

.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: var(--login-input-active-autofill) !important;
}

.login-header {
  height: 95px;
  background: var(--login-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-body {
  background: var(--ant-dropdown-bg);
  border-radius: 0 4px 4px 0;
}

.login-body-input,
.login-body-input input {
  border-radius: 0px;
  padding: 0.6rem;
}

.login-body-input input {
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login-body button {
  padding: 1rem;
  border-radius: 6px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 17px;
  border: none;
}

.login
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  background: #202229;
}

.hr-line hr {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #303238;
  width: 90px;
}

.forgot-password a,
.forgot-password-text {
  color: var(--secondary-blue);
}
.forgot-password-text {
  text-decoration: underline;
  cursor: pointer;
}
.social-medium-icons img {
  cursor: pointer;
}
.password-recovery {
  line-height: 2.5rem;
  color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 10px;
}
.recovery-password-container {
  color: #848589;
  font-size: 14px;
  letter-spacing: 0.03px;
  line-height: 16px;
  text-align: center;
}

.recovery-outer-circle {
  width: 64px;
  background: var(--login-background);
  border: 1px solid transparent;
  border-radius: 50%;
  height: 64px;
  margin: 10px auto;
  position: relative;
}
.recover-inner-circle {
  background: var(--login-background);
  border: 1px solid transparent;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  margin: auto;
  text-align: center;
  padding: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.hr-line span {
  padding: 0 1.2rem;
  color: #848589;
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login-body p {
  text-align: center;
  color: #848589;
  font-size: 11px;
  letter-spacing: 0.03px;
  line-height: 12px;
}

.login-body h1 {
  color: var(--text-color);
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
  text-align: center;
}

.login-body-select {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #303238;
  border-radius: 4px;
  background-color: #202229;
}

.login-body-select .ant-select-selector span {
  width: 100%;
  box-sizing: border-box;
  background-color: #202229;
  color: #848589;
  font-size: 13px;
  letter-spacing: 0.03px;
  line-height: 14px;
}

.login-body
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 2.5rem;
  border: none;
}

.login .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 2.5rem;
}

.login-body
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 0px;
}

.forgot-password a {
  color: var(--secondary-blue);
}

.login-p {
  padding: 0 16px 0 16px;
}

.login-footer-span-register {
  text-decoration: underline;
  color: #00aaff;
}

@media screen and (max-width: 768px) {
  .login-bg {
    display: none;
  }

  .login-body {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .login-header {
    justify-content: flex-start;
    padding: 10px 16px 0 16px;
  }

  .w-100 {
    width: 100vw;
  }
  .login-lable {
    display: block;
    color: var(--text-color);
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.04px;
    line-height: 19px;
    padding-bottom: 15px;
  }
  .login-footer-span-register {
    /* display: block; */
    padding-top: 15px;
  }
}
.recovery-error-text {
  color: red;
  font-size: 12px;
  letter-spacing: 0.03px;
  line-height: 12px;
  margin-bottom: 5px;
}
.cursor-not-allowed {
  cursor: not-allowed;
}

@media screen and (max-width: 786px) {
}

@media screen and (max-width: 480px) {
  .login {
    margin-top: 10px;
  }
}
