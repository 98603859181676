.set-main {
  padding: 33px;
}

.set-main-half {
  width: 50%;
}

.set-inp-btn-box {
  padding: 5px;
  border-radius: 4px;
  background-color:  var(--payment-input-bg);
}

.set-box-input {
  border: 0px;
  border-radius: 4px;
  background: var(--payment-input-bg);
  border: var(--payment-input-border);
  font-size: 14px;
  outline: none;
  padding: 8px;
}

.sett-send-btn {
  width: 25%;
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  font-size: 14px;
  padding: 8px;
  text-align: center;
}

.set-save-btn {
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  width: 50%;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.set-canc-btn-border {
  width: 50%;
  padding: 2px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  border-radius: 4px;
  cursor: pointer;
}

.set-canc-btn {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.mobile-block{
  display: none;
}

@media screen and (max-width: 666px) {
  .set-main{
    padding: 40px 10px 10px;
  }

  .set-main-half{
    width: 100%;
  }

  .set-main-col{
    flex-direction: column;
  }

  .fontSize-12{
    font-size: 12px;
  }

  .set-canc-btn-border{
    width: 20%;
  }

  .set-save-btn{
    width: 80%;
  }

  .mobile-block{
    display: block;
  }

  .mobile-none{
    display: none;
  }
}
