.shape-img {
  height: 120px;
  width: 120px;
  filter: var(--mail-img);
}
.empty-text {
  color: var(--text-color);
}
.empty {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.mainHistory {
  width: 100%;
  height: 320px;
}

.ant-table-content {
  background-color: var(--home-background);
  color: var(--text-color);
}

.ant-table-thead .ant-table-cell {
  background-color: var(--right-section-sub-main);
  color: var(--text-color);
  border-bottom: none;
}

.ant-table-row:hover {
  background-color: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

td.ant-table-cell {
  padding: 8px 16px !important;
}
td.ant-table-cell > p {
  margin: 0px !important;
}

.ant-table-tbody > tr > td {
  border-bottom: var(--table-border-bottom);
}
.ant-pagination {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: var(--payment-input-bg);
  color: var(--text-color);
}

.ant-pagination-item-active {
  border: 1px solid blue;
}
@media only screen and (max-width: 768px) {
  .ant-table-content {
    padding-bottom: 20px;
  }
  .ant-pagination {
    top: -35px;
  }
}
