.casino-header-body {
  background-color: var(--top-bets-bg);
  box-sizing: border-box;
  height: 283px;
  padding: 7px;
}
.nav-dropBtn-padd {
  padding: 10px 20px !important;
}
.drop-down-mobile {
  display: flex !important;
}
.row-container {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.ant-modal-close-x {
  font-size: 1rem;
}
.casino-header-body h1 {
  color: var(--text-color);
  font-family: var(--Roboto);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 21px;
}

.casino-header-body h2 {
  color: var(--footer-text-clr);
  font-family: var(--Roboto);
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 16px;
  margin-bottom: 0;
}

.casino-header-body p {
  color: var(--footer-text-clr);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 20px;
}

.casino-header-body h3 {
  color: red;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.04px;
  line-height: 20px;
  margin-bottom: 0;
}

.casino-header-body .custom-progress {
  transform: rotate(270deg);
  height: 136px;
  border-radius: 3px;
}

.casino-header-body img {
  margin-bottom: 1rem;
}
.casino-scroll::-webkit-scrollbar,
.game-body::-webkit-scrollbar {
  width: 5px;
}

.casino-scroll::-webkit-scrollbar-track,
.game-body::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #28304c;
}

.casino-scroll::-webkit-scrollbar-thumb,
.game-body::-webkit-scrollbar-thumb {
  background-color: #3f8aff;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}
.provider-mobile-container {
  display: none;
}
.provider-mobile-heading {
  color: var(--text-color);
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  background: var(--games-header-background);
}
.provider-content-heading {
  margin-left: 10px;
}
.games {
  background: var(--casino-bg-clr);
  padding: 0.5rem 0;
}

.casino-game {
  padding: 1rem 0;
  border-radius: 4px 4px 0 0;
  background: var(--home-background);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
}

.casino-game h2 {
  color: var(--footer-text-clr);
  font-family: var(--Poppins);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 0;
}

.casino-game ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.casino-game img {
  height: 28px;
  width: 28px;
}

.casino-game input {
  border: 1px solid var(--search-text-border);
  border-radius: 10px;
  background-color: var(--footer-background);
  height: 40px;
  width: 382px;
  font-size: 14px;
  letter-spacing: 0.04px;
  color: var(--search-text-clr);
  outline: none;
}

.casino-game .search-icon {
  color: #67696f;
}

.game-body {
  border-radius: 4px;
  background-color: var(--footer-background);
  margin: 1rem;
  /* height: 100vh;
  overflow-y: auto; */
}

.game-body h1 {
  color: var(--text-color);
  font-family: var(--Poppins);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
}

.game-body p {
  color: var(--footer-text-clr);
  font-family: var(--Roboto);
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
  cursor: pointer;
}

.game-body .ant-switch-checked:focus {
  box-shadow: none;
}

.game-card-body {
  height: 49px;
  width: 321px;
  border-radius: 0 0 4px 4px;
  background-color: rgba(16, 18, 25, 0.9);
}

.game-card-body h5 {
  color: var(--white);
  font-family: var(--Poppins);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}
.navbar-carousel {
  background-color: #101219;
}
.game-card {
  overflow: hidden;
}

.game-card:hover .game-card-body {
  height: 100%;
  width: 100%;
}

.card-play-btn {
  -ms-transform: skewX(0deg);
  transform: skewX(0deg);
  width: 131px;
  color: #ffffff;
}
.casino-header-color {
  color: var(--text-color);
  filter: brightness(0) invert(1);
}

#games-category-hightlight {
  color: var(--text-color);
}
#games-category-hightlight-1 {
  filter: brightness(0) invert(1);
}
#light-clr-hover:hover {
  filter: unset !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.data-not-available {
  text-align: center;
  margin-top: 150px;
  color: #92959a;
  font-family: var(--Poppins);
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 0;
}

.casino-games-nav {
  align-items: center;
  flex-wrap: wrap;
}

.casino-game-nav-arr-down {
  transform: rotate(-90deg);
  display: none;
}

.casino-game-nav-arr-up {
  transform: rotate(90deg);
  display: none;
}

.white-arr {
  color: white;
  display: none;
  padding-right: 10px;
}

.header-input input {
  display: none;
}
@media screen and (min-width: 1000px) {
  .top-win-container-mobile {
    display: none;
  }
}
.casino-right-hr-top-win {
  background: linear-gradient(
    -45deg,
    #0091ff 30%,
    #0091ff 40%,
    white 60%,
    transparent
  );
  height: 1px;
  margin-bottom: 10px;
}
.casino-scroll.casino-scroll-mobile {
  height: 120px;
}
/* .ant-modal-close-x {
  padding-top: 15px;
} */
@media screen and (min-width: 1000px) {
  .drop-down-mobile {
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
  .provider-desktop {
    display: block;
  }

  .casino-header-body {
    width: 100%;
    height: auto;
  }
  .top-win-games {
    padding-bottom: 5px;
  }
  .top-win-games > img,
  .top-win-contatiner-desktop {
    display: none;
  }
  .casino-games-nav {
    flex-direction: column;
    align-items: stretch;
    display: none !important;
  }

  .casino-game-menu {
    padding: 7px 16px 7px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .white-arr {
    display: block;
  }

  .casino-game-nav-arr-down {
    display: block;
  }

  .casino-game-nav-arr-up {
    display: block;
  }

  .casino-game-menu-el {
    display: none;
  }

  .casino-game-menu-el-b {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .casino-hide-input {
    display: none;
  }

  .casino-game {
    padding: 0;
  }

  .casino-game-menu-title-p {
    padding: 10px 16px 10px 16px;
  }

  .game-body h1 {
    font-size: 16px;
  }

  .game-body p {
    font-size: 12px;
  }

  .header-input input {
    display: block;
    margin: 0 auto 15px auto;
    border-radius: 10px;
    height: 40px;
    width: 90%;
    outline: none;
    border: 1px solid var(--search-text-border);
    background-color: var(--footer-background);
    font-size: 14px;
    color: var(--search-text-clr);
  }
  .mail-modal .ant-modal-close-x {
    padding-top: 15px;
  }
}

.show-more-container {
  width: 12rem;
  margin: 12px auto;
  padding: 1rem;
}
.show-more-btn {
  height: 3rem;
  text-transform: uppercase;
  font-weight: bold;
}

.ant-modal.casino-play-now {
  top: 0;
  margin: unset !important;
}
.casino-play-now > .ant-modal-content {
  width: 100vw !important;
  height: 100vh !important;
  background-color: var(--sidebar-bg);
}

.games-cards .games-cards-footer {
  padding: 1rem;
  color: var(--text-color);
  border-radius: 0 0 4px 4px;
  background: var(--games-card-footer);
  overflow: hidden;
  height: 50px;
  width: 100%;
}
.games-cards-footer h5 {
  color: var(--text-color);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.games-cards:hover .games-cards-footer {
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}
.card {
  --bs-card-bg: var(--games-card-footer);
}
.card-img-top {
  width: 300px;
  height: auto;
  object-fit: cover;
}
.games-cards {
  width: 321px;
  height: 246px;
}
.heart-size {
  font-size: 20px;
  color: red;
}
.heart-size2 {
  font-size: 20px;
}
@media screen and (max-width: 1440px) {
  .card-img-top {
    height: auto !important;
    width: 300px; 
  }
}
@media screen and (max-width: 1280px) {
  .card-img-top {
    height: auto !important;
    width: 250px; 
  }
}
@media screen and (max-width: 1024px) {
  .card-img-top {
    height: auto !important;
    width: 200px; 
  }
}
@media screen and (max-width: 768px) {
  .games-cards .games-cards-footer {
    height: 70px;
  }
  .card-img-top {
    height: auto !important;
    width: 150px; 
  }
  .games-cards {
    width: 150px !important;
    height: 130px !important;
  }
  .row-container {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
  .name-input {
    width: 100% !important;
  }
  .login-body button {
    font-size: 12px;
    padding: 1em 2rem;
  }
  .row-container > * {
    width: auto !important;
  }
  .games-cards-footer h5 {
    font-size: 12px;
  }
  .heart-size {
    font-size: 17px;
  }
  .heart-size2 {
    font-size: 17px;
  }
  .demo-mobile {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .demo-text {
    font-size: 14px;
  }
  .card-play-btn {
    padding: 0;
    font-size: 14px;
    line-height: 14px;
    height: 30px;
    width: 80px;
  }
  .games-cards .games-cards-footer {
    padding: 15px 0px 25px 0px;
  }
  .games-cards-footer h5 {
    padding-left: 2px;
    line-height: 25px;
  }
}
@media screen and (max-width: 480px) {
  .provider-mobile-container {
    display: block;
    position: relative;
  }
  .react-multi-carousel-list.py-1.carol-position {
    position: unset !important;
    background: #101219;
    width: 90%;
    margin: 8px auto;
  }
  .carol-position > li,
  .carol-position li.react-multi-carousel-item {
    width: 100px !important;
  }
  .provider-mobile {
    right: 10px;
    top: 10px;
  }

  .provider-desktop {
    display: none;
  }
  .card-img-top {
    height: auto !important;
    width: 115px; 
  }
  .games-cards {
    width: 100px !important;
    height: 50px !important;
  }
  .row-container {
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }
  .row-container > * {
    width: auto !important;
  }
  .name-input {
    width: 100% !important;
  }
  .games-cards-footer h5 {
    font-size: 10px;
  }
  .heart-size {
    font-size: 15px;
  }
  .heart-size2 {
    font-size: 15px;
  }
  .games-cards .games-cards-footer {
    padding: 15px 0px 30px 0px;
  }
  .demo-mobile {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .demo-text {
    font-size: 12px;
  }
  .card-play-btn {
    margin-top: 15px;
    padding: 0px;
    font-size: 12px;
    line-height: 12px;
    height: 25px;
    width: 75px;
  }
  .games-cards-footer h5 {
    padding-left: 2px;
    line-height: 15px;
  }
  .ant-modal-close-x {
    padding-top: 0px;
  }
  .mail-modal .mail-modal-header h1 {
    height: 60px;
    justify-content: start;
  }
  .ant-modal-content {
    height: 100vh;
    background-color: var(--right-section-bg);
  }

  .games-cards:hover .games-cards-footer {
    position: unset;
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .games-cards {
    width: 90px !important;
  }
  .card-img-top {
    height: auto !important;
    width: 75px;  
  }
}

@media screen and (max-width: 350px) {
  .games-cards {
    width: 70px !important;
  }
}
@media screen and (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: auto !important;
  }
}
