 .ver-title {
     font-weight: 500;
     padding: 0 30px;
 }

 .ver-list {
     margin-top: 50px;
     margin-left: -15px;
     line-height: 25px;
     padding: 0 30px;
 }

 .ul-list {
     list-style: none;
 }

 .ul-list::before {
     content: "\2022";
     color: #0091FF;
     font-weight: bold;
     display: inline-block;
     width: 16px;
     margin-left: -1em;
 }

 /*
.chooseDocument {
    margin-top: 40px;
}

.chooseDocument-form {
    margin-left: 0;
    padding-left: 0;
    margin-right: 20px;
}

.chooseFile-btn-box {
    width: 140px;
    height: 42px;
    margin-top: 34px;
    margin-right: 15px;
    padding: 1px;
    background: linear-gradient(270deg, #7271ff, #00aaff);
    border-radius: 6px;
}

.chooseFile-btn {
    height: 100%;
    width: 100%;
    margin: 0 auto 10px auto;
    border-radius: 6px;
    border: none;
    background-color: #141822;
    font-size: 14px;
}

.chooseFile-img {
    margin-right: 10px;
}
*/

 .verify-btn {
     height: 42px;
     width: 144px;
     border-radius: 6px;
     background: linear-gradient(270deg, #7270FF 0%, #00AAFF 100%);
     margin-top: 34px;
     border: none;
 }


 .ver .select-label {
     color: #FFFFFF;
     font-family: Roboto;
     font-size: 16px;
     font-weight: 500;
     letter-spacing: 0.04px;
     line-height: 19px;
     padding-bottom: 10px;
 }

 .ver .custom-select {
     height: 40px;
     width: 100%;
     border-radius: 4px;
     background-color: var(--ant-dropdown-bg);
 }


 .ver .border-gradient {
     background: linear-gradient(#181b25, #181b25) padding-box,
         linear-gradient(270deg, #7270ff 0%, #00aaff 100%) border-box;
     border: 1px solid transparent;
     height: 40px;
     width: 134px;
     border-radius: 4px;
     margin-top: 32px;
     color: white;
 }

 .ver .border-gradient span {
     height: 16px;
     width: 83px;
     color: #FFFFFF;
     font-family: Roboto;
     font-size: 14px;
     letter-spacing: 0.04px;
     line-height: 16px;
 }

 .ver .image-text {
     font-size: 14px;
     margin-top: 40px;
     color: white;
     font-family: Roboto;
     letter-spacing: 0.04px;
     line-height: 16px;
 }

 .custom-btn-flex{
    display: flex;
 }

 @media screen and (max-width: 666px) {
    .ver-title{
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: -20px;
    }

    .ver-list{
        font-size: 12px;
    }

    .ver-mobile{
        flex-direction: column;
        width: 100%;
    }

    .custom-btn-flex{
       display: flex;
       justify-content: space-between;
       width: 100%;
    }

    .custom-select{
        width: 100%;
        margin-bottom: -20px;
    }

    .choose-cont{
        width: 100%;
    }

    .ver{
        box-shadow: inset 5px 20px 15px -23px rgba(0,0,0,0.85);
        border-radius: 4px;
    }
 }