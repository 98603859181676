.promotions-card-container {
  height: 234px;
  width: 622px;
  border-radius: 22px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 30px 25px;
  position: absolute;
  bottom: 40px;
  left: 30px;
  color: var(--clr-white);
}
.promotions-card-heading {
  font-family: var(--fontFamilyPopins);
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0.13px;
  line-height: 58px;
  text-shadow: 0 0 29px 0 #ffffff;
}

.promotions-card-content {
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.04px;
  line-height: 19px;
}

.promotions-card-hr-line {
  height: 1px;
  width: 572px;
  transform: scaleX(-1);
  box-shadow: 0 0 29px 0 rgba(255, 255, 255, 0.99);
  margin: 10px 0;
}

.promotion-categories {
  color: #92959a;
  font-family: var(--fontFamilyPopins);
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 20px;
}
.promotion-category-container {
  margin: 0 20px;
  cursor: pointer;
}
.promotion-category-container-active {
  color: var(--clr-white);
}
.promotion-category-container:hover {
  color: var(--clr-white);
  text-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5);
}

.promotion-category-section {
  border-radius: 4px;
  background-color: #141822;
  box-shadow: 0 0 10px 0 #000000;
  width: 96%;
  margin: auto;
  padding: 1rem;
}

.promotion-section-hr-line {
  height: 1px;
  transform: scaleX(-1);
  box-shadow: 0 0 29px 0 rgba(23, 162, 218, 0.99);
}

.single-promotions-container {
  width: 80%;
  margin: auto;
  color: var(--clr-white);
}

.single-promotion-header {
  color: var(--clr-white);
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.06px;
  line-height: 28px;
  text-shadow: 0 0 29px 0 #ffffff;
}

.single-promotions-section,
.single-promotion-cards {
  border-radius: 4px;
  background-color: #141822;
  box-shadow: 0 0 10px 0 #000000;
}
.single-promotions-section-img {
  text-align: center;
}

.single-promotion-cards {
  width: 95%;
  margin: auto;
  transform: scaleY(-1);
}
