.main-help-container{
    background: #191E29;
    color: white;
}

.help-container{
    padding: 50px 20px 50px 50px;
}

.help-left,
.help-right{
    background-color: #141822;
}

.help-left{
    width: 63%;
}

.help-right{
    width: 35%;
}

.contact-us{
    padding: 40px 30px 30px;
    color: rgba(255,255,255,0.5);
}