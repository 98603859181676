.newTicket-btn {
  padding: 7px 10px;
  width: 181px;
  border-radius: 4px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04px;
  text-align: center;
}

.newTicket-btn-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

@media screen and (max-width: 768px) {
  .newTicket-btn-container {
    padding: 20px 50px 20px 0;
  }
}

@media screen and (max-width: 425px) {
  .newTicket-btn-container {
    justify-content: center;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
    padding-right: 0;
  }

  .newTicket-btn {
    width: 60%;
    font-size: 12px;
  }
}
