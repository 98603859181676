.history-main {
  background: var(--deposit-main-bg);
  padding: 29px 0 0 0;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
}

.empty {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04px;
}

.custom-select {
  padding: 5px 0;
  width: 193px;
  border-radius: 4px;
  background-color: var(--ant-dropdown-bg);
  margin-right: 50px;
}

.ant-select-dropdown {
  background-color: var(--ant-dropdown-bg);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-item-option-active {
  background: linear-gradient(270deg, #7270ff, #00aaff);
}

.ant-select-item-option-selected {
  background: linear-gradient(270deg, #7270ff, #00aaff);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #272a33;
}

.select-label {
  color: var(--text-color);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: 19px;
  padding-bottom: 10px;
}

.ant-select-item-option {
  color: var(--text-color);
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: none;
}

@media screen and (max-width: 768px) {
  .history-main {
    border-radius: 15px;
    box-shadow: 0 0 10px 0 #000000;
  }

  .custom-select {
    width: 120px;
    font-size: 13px;
    height: 40px;
    margin-right: 20px;
  }

  .select-label {
    font-size: 14px;
  }
}

@media screen and (max-width: 666px) {
  .betHistory-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .custom-select {
    width: 95%;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .select-label {
    font-size: 12px;
  }

  .title-border {
    display: none;
  }

  .histUrl-column {
    padding: 20px 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
    border-radius: 4px;
  }

  .history-main {
    padding: 15px 0 0 0;
  }
}
