.nav {
  padding: 0 33px;
}

.main-main {
  width: 68%;
  background: var(--deposit-main-bg);
  height: 100%;
  padding: 15px 0;
  color: var(--text-color);
  border-radius: 4px;
  /* box-shadow: var(--deposit-box-shadow); */
}

.main-title {
  padding: 0 33px;
}

.mainSection-container {
  max-height: 600px;
  overflow-y: auto;
}

.main-full-screen {
  width: 100%;
}

.add-btn {
  background: linear-gradient(270deg, #7270ff, #00aaff);
  padding: 7.7px;
  border-radius: 4px;
  text-align: center;
  margin-right: 34px;
  width: 13%;
  align-self: flex-end;
}

.mainSection-container::-webkit-scrollbar {
  width: 4px;
}

.mainSection-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.mainSection-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #ffffff;
}

.mainSection-container::-webkit-scrollbar:horizontal {
  width: 4px;
  height: 8px;
}

@media screen and (max-width: 768px) {
  .main-main {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 15px;
    /* box-shadow: 0 0 10px 0 #000000; */
  }

  .mainSection-container {
    height: 400px;
    overflow-y: auto;
  }

  .add-btn {
    width: 25%;
  }
}

@media screen and (max-width: 666px) {
  .main-title {
    flex-direction: column;
    font-size: 14px;
    margin-top: 10px;
  }
  .dropdown {
    margin-left: 0;
  }
  .main-main {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.85);
  }

  .add-btn {
    align-self: center;
    width: 60%;
    margin: 20px 0;
    font-size: 14px;
  }

  .formPay-responsive {
    margin-right: -25px;
    width: 60%;
  }

  .selPay-responsive {
    font-size: 15px;
    /* margin-left: -15px; */
  }

  .banksEmpty {
    font-size: 12px;
  }
  .card-second {
    flex-direction: column;
    /* height: 25%; */
    /* justify-content: space-around; */
  }
  .nav {
    padding: 0;
  }
  .main-title {
    padding: 0;
  }
}
