.siderBar-main {
  position: absolute;
  top: 0%;
  left: 0;
  color: var(--text-color);
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.ModalOpen {
  animation: openModal 0.4s ease-out forwards;
}

@keyframes openModal {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.sideBar-container {
  width: 100%;
  height: 100vh;
  background-color: var(--navbar-mobile-bg-clr);
  /* padding: 0 4px 5px; */
}
.sidebar-main-container {
  width: 100vw;
  background-color: var(--sidebar-main-container-bg);
}

.siderBar-none {
  display: none;
}

.siderBar-block {
  display: block;
}
.sideBar-header {
  position: relative;
  height: 70px;
}

.sideBar-arrow-container {
  position: absolute;
  left: 10px;
  top: 20px;
  width: 40px;
  height: 40px;
  background-color: var(--navbar-mobile-bg-clr);
  border-radius: 50%;
}

.sideBar-border {
  height: 1px;
  width: 90%;
  background: linear-gradient(270deg, #141822, #00aaff);
  margin: 5px 0;
}
.sideBar-border {
  position: absolute;
  bottom: 45px;
}
.sideBar-menu-container {
  margin-top: 10px;
}

.sideBar-menu {
  padding: 0 15px;
  padding-left: 0;
  background: var(--navbar-sidebar-bg);
  margin-bottom: 5px;
  margin-left: -5px;
}

.left-border {
  width: 3px;
  height: 45px;
  background: linear-gradient(270deg, #7270ff 0%, #00aaff 100%);
  box-shadow: 0 0 10px 0 #537fff;
}

.sideBar-menu-img {
  margin: 0 10px 0 30px;
}
/* .sideBar-menu-img.img {
  width: 50px;
  height: 50px;
} */
#sidebar-img {
  width: 50px;
  height: 60px;
  margin: 0;
}

.sideBar-menu-content,
.sideBar-menu-content-menu {
  font-family: Poppins;
  font-size: 14px;
}
.sideBar-menu-content-menu {
  margin-left: -10px;
}
.sideBar-menu-border {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 7px 0;
}

.listName-padding {
  padding: 9px 5px;
}

#sidebar-menu-select {
  margin: 0px;
}

.logInAccount-container {
  padding: 20px;
}

.clientLogIn {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.7px;
}

.emailLogIn {
  color: var(--text-color);
  font-size: 10px;
  letter-spacing: 0.7px;
}

.rotate_90-left {
  transform: rotate(90deg);
}

.sidebar-link {
  text-decoration: none;
  color: var(--text-color);
}

.sideBar-Select {
  width: 100%;
  background-color: #00aaff;
}

.option-menu-img {
  margin-right: 15px;
  margin-left: 5px;
}
