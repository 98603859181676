.ant-select-arrow {
  z-index: 1;
  color: var(--white);
}

.register-custom-select-icon {
  z-index: 9;
  top: 10px;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: var(--text-color)
}

.register-body
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  padding: 0 1.8rem;
}

.login-body .ant-checkbox + span {
  color: #848589;
  font-size: 11px;
  letter-spacing: 0.03px;
  line-height: 13px;
  align-self: flex-start;
}

.login-body
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  padding: 5px 2rem;
  z-index: 1;
  color: var(--white);
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.65);
  padding-top: 80px;
}

.ant-steps-item-title::after {
  display: none !important;
}

.ant-steps-item-icon {
  width: 90px !important;
  border-radius: 0 !important;
}

.input-cal-light input[type="date"] {
  color-scheme: dark;
}

.input-cal-dark input[type="date"] {
  color-scheme: light;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  cursor: pointer;
  height: auto;
  left: 90%;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"] {
  text-transform: uppercase;
}
input[type="date"]::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}
input[type="date"]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
input[type="date"]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}
input[type="date"]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}
input[type="date"]::placeholder {
  /* Recent browsers */
  text-transform: none;
} 