.bonus-main {
  background: var(--deposit-main-bg);
  padding: 29px 0;
  color: var(--text-color);
}

@media screen and (max-width: 768px) {
  .bonus-main {
    border-radius: 15px;
    box-shadow: 0 0 10px 0 #000000;
  }
}

@media screen and (max-width: 425px) {
  .bonus-nav-mob {
    display: none;
  }

  .bonus-main {
    padding: 8px 0;
  }
}
